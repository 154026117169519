import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { match, history } from 'common';
import { isValidSchema, urlSchema, textSchema, arraySchema } from 'common/shemaValidator';
import { commonFileUpload, formatUsername, getDSTDate, setDSTDateString } from 'common';
import Env from 'env';

import {
  EditorTitle,
  Button,
  FieldGroup,
  Select,
  TextEditor,
  Downloads,
  Prompt,
  InfoTooltip,
  Modal,
} from 'modules/admin/shared';
import { DateTimePicker } from 'modules/shared';
import Recommendation from './Recommendation';
import KeyFacts from './KeyFacts';
import ImageHeader from './ImageHeader';
import { arrayMove } from 'react-sortable-hoc';
import moment from 'moment';
import styles from './PostsEditor.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import SyncPopupContent from './SyncPopupContent';

class PostsEditor extends Component {
  static propTypes = {
    companies: PropTypes.array,
    categories: PropTypes.array,
    employees: PropTypes.array,
    regions: PropTypes.array,
    tags: PropTypes.array,
    investmentStyles: PropTypes.array,
    questions: PropTypes.array,
    sectors: PropTypes.array,
    sector_industries: PropTypes.array,
    entitlements: PropTypes.array,
    marketcaps: PropTypes.array,
    contributors: PropTypes.array,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getAdminPost: PropTypes.func.isRequired,
    savePost: PropTypes.func.isRequired,
    modifyPost: PropTypes.func.isRequired,
    saveFeaturedImage: PropTypes.func.isRequired,
    saveContentImage: PropTypes.func.isRequired,
    deleteDownloadFile: PropTypes.func.isRequired,
    systemSettings: PropTypes.object.isRequired,
    commonUploadFile: PropTypes.func,
    getEmployees: PropTypes.func,
    getCompanies: PropTypes.func,
    getCategories: PropTypes.func,
    getRegions: PropTypes.func,
    getQuestions: PropTypes.func,
    getSectors: PropTypes.func,
    getIndustrySectors: PropTypes.func,
    getEntitlements: PropTypes.func,
    getTags: PropTypes.func,
    saveTag: PropTypes.func,
    getInvestmentStyles: PropTypes.func,
    getMarketcaps: PropTypes.func,
    getContributors: PropTypes.func,
    basePath: PropTypes.string,
    match: match.isRequired,
    history: history.isRequired,
    hiddenFields: PropTypes.array,
    categoriesMulti: PropTypes.bool,
    categoryNoteOrVideo: PropTypes.bool,
    intl: PropTypes.object,
    syncSharepoint: PropTypes.func,
    checkSharepointSync: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.contentRef = createRef();
    this.investmentThesisRef = createRef();
  }

  /**
   * @returns {Object} return new file object.
   */
  static getFile() {
    return { custom_properties: { title: '', description: '' }, data: {}, file_name: '' };
  }

  /**
   * @returns {Object} return new file object.
   */
  static getTopList() {
    return { title: '', description: '', logo: '' };
  }

  formChanged = false;
  edit = !!this.props.match.params.id;
  state = {
    showPopup: false,
    showSaveAndPublishPopup: false,
    showOutOfSyncPopupSave: false,
    showOutOfSyncPopupPublish: false,
    showOutOfSyncPopupSaveAndPublish: false,
    sharepoint_file_updated_at: null,
    post: {
      sharepoint_url: '',
      sharepoint_last_sync_at: null,
      title: '',
      price: '',
      price_target: '',
      video_url: '',
      buy_type: '',
      status: 'Draft',
      published_at: null,
      description: '',
      authors: [],
      author_ids: [],
      downloads: [],
      summary: '',
      content: '',
      video_transcription: '',
      company: '',
      investment_thesis: '',
      article_pdf: false,
      key_facts: false,
      key_facts_2: false,
      key_facts_3: false,
      recommendation: '',
      forecast_return: '',
      ticker: '',
      market_cap: '',
      '3m_average': '',
      valuation_metric: '',
      current_multiple: '',
      target_multiple: '',
      shares_out: '',
      gradient_start: this.getGradientStart(),
      gradient_end: this.getGradientEnd(),
      show_author_info: false,
      related_companies: [],
      disclaimer_companies: [],
      additional_related_companies: [],
      like_questions: [],
      recommendation_history: false,
      recommendation_history_title: '',
      featured_image_path: '',
      categories_id: [],
      article_companies_id: [],
      related_companies_id: [],
      additional_related_companies_id: [],
      like_questions_id: [],
      tags: [],
      tag_id: [],
      geography: [],
      geography_id: [],
      region: '',
      region_id: [],
      investment_style_id: [],
      marketcap: [],
      marketcap_id: [],
      investment_style: [],
      idea_source_id: '',
      video_description: '',
      recommendation_section: '',
      recommendation_title: '',
      investment_type: '',
      timeframe: '',
      ivps_upside_case: '',
      ivps_downside_case: '',
      updown_ratio: '',
      bonus: false,
      toplist: [],
      sectors: [],
      sector_industries: [],
      entitlements: [],
      generate_pdf: true,
      company_id1: null,
      company_id2: null,
      company_id3: null,
      send_data_to_worldflow: true,
    },
    errors: {},
    isValid: false,
    loading: true,
    isReady: true,
    isSynced: true,
    syncInProgress: false,
    outOfSyncData: null,
    changedFeaturedImage: false,
    apiList: [],
    tagOptions: [],
    hiddenFields: [],
    regions: [],
  };

  getSchema = (video) => {
    return {
      sharepoint_url: Yup.string()
        .trim()
        .nullable(),
      ...textSchema('title', { required: true }),
      ...urlSchema('video_url', { required: video }),
      ...textSchema('recommendation_history_title'),
      ...textSchema('video_description'),
      ...textSchema('buy_type'),
      ...textSchema('company'),
      ...textSchema('summary', { required: true }),
      ...textSchema('content', { max: Env.MAX_TEXT_EDITOR_LENGTH }),
      ...textSchema('video_transcription', { max: Env.MAX_TEXT_EDITOR_LENGTH }),
      ...textSchema('timeframe'),
      ...textSchema('investment_type'),
      ...textSchema('recommendation_title'),
      ...textSchema('ivps_upside_case'),
      ...textSchema('ivps_downside_case'),
      ...textSchema('updown_ratio'),
      ...textSchema('recommendation'),
      ...textSchema('price'),
      ...textSchema('price_target'),
      ...textSchema('forecast_return'),
      ...textSchema('3m_average'),
      ...textSchema('market_cap'),
      ...textSchema('ticker'),
      ...textSchema('valuation_metric'),
      ...textSchema('shares_out'),
      ...textSchema('region'),
      ...textSchema('current_multiple'),
      ...textSchema('target_multiple'),
      ...arraySchema('authors', {
        required: !this.state.hiddenFields.includes('author_id'),
        max: this.props.systemSettings.post_max_authors,
      }),
      ...arraySchema('sectors', {
        required:
          !this.state.post.company_id1 &&
          !this.state.post.company_id2 &&
          !this.state.post.company_id3,
      }),
      ...arraySchema('sector_industries', {
        required:
          !this.state.post.company_id1 &&
          !this.state.post.company_id2 &&
          !this.state.post.company_id3,
      }),
      ...arraySchema('entitlements', {
        required:
          !this.state.post.company_id1 &&
          !this.state.post.company_id2 &&
          !this.state.post.company_id3,
      }),
      ...textSchema('company_id1', {
        required: this.state.post.key_facts || this.state.post.company_id2,
      }),
      ...textSchema('company_id2', {
        required: this.state.post.key_facts_2 || this.state.post.company_id3,
      }),
      ...textSchema('company_id3', { required: this.state.post.key_facts_3 }),
      downloads: Yup.array()
        .of(
          Yup.object().shape({
            custom_properties: Yup.object()
              .shape({
                title: Yup.string()
                  .max(
                    Env.MAX_TEXT_FIELD_LENGTH,
                    `Cannot be longer than ${Env.MAX_TEXT_FIELD_LENGTH} characters.`
                  )
                  .nullable(),
                description: Yup.string()
                  .max(
                    Env.MAX_TEXT_FIELD_LENGTH,
                    `Cannot be longer than ${Env.MAX_TEXT_FIELD_LENGTH} characters.`
                  )
                  .nullable(),
              })
              .nullable(),
          })
        )
        .nullable(),
    };
  };

  /**
   * @returns {string} start gradient color
   * */
  getGradientStart() {
    switch (this.props.basePath) {
      case '/edge-plus': {
        return Env.POST_GRADIENT_START_EDGE_PLUS;
      }
      case '/edge': {
        return Env.POST_GRADIENT_START_EDGE;
      }
      case '/analyst': {
        return Env.POST_GRADIENT_START_ANALYST;
      }
      default: {
        return Env.POST_GRADIENT_START_ANALYST;
      }
    }
  }

  /**
   * @returns {string} end gradient color
   * */
  getGradientEnd() {
    switch (this.props.basePath) {
      case '/edge-plus': {
        return Env.POST_GRADIENT_END_EDGE_PLUS;
      }
      case '/edge': {
        return Env.POST_GRADIENT_END_EDGE;
      }
      case '/analyst': {
        return Env.POST_GRADIENT_END_ANALYST;
      }
      default: {
        return Env.POST_GRADIENT_END_ANALYST;
      }
    }
  }

  getMultiSelectData = (data, nameKey, nameKey_2, idKey, primaryNameKey = '') => {
    return data
      ? data.map((item) => ({
          label: nameKey_2
            ? `${item[primaryNameKey] || item[nameKey] || ''} ${item[nameKey_2] || ''}`
            : item[primaryNameKey] || item[nameKey],
          value: idKey ? item[idKey] : item.id,
        }))
      : [];
  };

  getSingleSelectData = (data, nameKey) => {
    return data && data[0] ? { label: data[0][nameKey], value: data[0].id } : {};
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();
    if (this.edit) {
      try {
        const [post, ...response] = await Promise.all([
          this.props.getAdminPost(this.props.match.params.id),
          ...this.getApiLists(),
        ]);

        const { getTags, getQuestions, getRegions } = this.getApiListResponse(response);

        const like_questions = this.getLikedQuestions(
          getQuestions,
          this.getMultiSelectData(post.like_questions, 'question', null, 'type_id', 'base_question')
        );

        const authors = this.getMultiSelectData(post.authors, 'firstname', 'lastname');
        const errors = await this.isValidSchema('authors', authors);
        const generate_pdf = !!post.downloads.find(
          (item) => item.custom_properties.automatically_generated
        );

        this.setState(
          {
            post: {
              ...post,
              categories: this.props.basePath.includes('edge-plus')
                ? this.getSingleSelectData(post.categories, 'name')
                : this.getMultiSelectData(post.categories, 'name'),
              authors,
              related_companies: this.getMultiSelectData(post.related_companies, 'title'),
              disclaimer_companies: this.getMultiSelectData(post.disclaimer_companies, 'title'),
              additional_related_companies: this.getMultiSelectData(
                post.additional_related_companies,
                'title'
              ),
              like_questions,
              tags: this.getMultiSelectData(post.tags, 'name'),
              geography: this.getMultiSelectData(post.geography, 'title'),
              region: post.region,
              investment_style: this.getMultiSelectData(post.investment_style, 'name'),
              marketcap: this.getMultiSelectData(post.marketcap, 'name'),
              toplist: post.toplist ? JSON.parse(post.toplist) : null,
              published_at: getDSTDate(post.published_at),
              sectors: this.getMultiSelectData(post.sectors, 'name'),
              sector_industries: this.getMultiSelectData(post.sector_industries, 'name'),
              entitlements: this.getMultiSelectData(post.entitlements, 'name'),
              generate_pdf,
              send_data_to_worldflow: true,
            },
            errors,
            tagOptions: getTags,
            alreadySetPublished: !!post.published_at,
            loading: false,
            isValid: true,
            hiddenFields: this.props.hiddenFields,
            regions: getRegions,
          },
          () => {
            this.hideFieldsByCategory();
          }
        );
      } catch (e) {
        this.setState({ loading: false });
      }
    } else {
      const response = await Promise.all([...this.getApiLists()]);
      const { getTags, getQuestions, getRegions } = this.getApiListResponse(response);

      const defaultCategory = this.getMultiSelectData(
        [this.props.categories.find((x) => x.slug === 'note')],
        'name'
      );
      this.setState(
        {
          post: {
            ...this.state.post,
            categories: this.props.categoriesMulti ? defaultCategory : defaultCategory[0],
            like_questions: this.getDefaultLikedQuestions(getQuestions),
          },
          tagOptions: getTags,
          loading: false,
          hiddenFields: this.props.hiddenFields,
          regions: getRegions,
        },
        () => {
          this.hideFieldsByCategory();
        }
      );
    }
    this.setState({
      isValid: await this.isValidForm(),
    });
    this.props.hideLoader();
  }

  /**
   * @param {array} questions like questions
   * @param {array} likedQuestions liked questions
   * @returns {array} default checked liked questions
   * */
  getLikedQuestions(questions, likedQuestions) {
    const defaultQuestions = this.getDefaultLikedQuestions(questions);
    const result = [...likedQuestions];
    defaultQuestions.forEach((question) => {
      if (!likedQuestions.find(({ value }) => +value === +question.value)) {
        result.unshift(question);
      }
      return question;
    });
    return result;
  }

  /**
   * @param {array} questions like questions
   * @returns {array} default checked liked questions
   * */
  getDefaultLikedQuestions(questions) {
    return questions
      .filter((question) => question.default)
      .map(({ question, id }) => ({ label: question, value: id }));
  }

  /**
   * @returns {array} callAble api list
   * */
  getApiLists() {
    const listApi = [
      'getEmployees',
      'getCompanies',
      'getCategories',
      'getRegions',
      'getTags',
      'getInvestmentStyles',
      'getContributors',
      'getMarketcaps',
      'getQuestions',
      'getSectors',
      'getIndustrySectors',
      'getEntitlements',
    ];

    const callAbleApiList = [];
    const callAbleApiListName = [];
    for (const api of listApi) {
      if (this.props[api]) {
        callAbleApiListName.push(api);
        callAbleApiList.push(this.props[api]());
      }
    }

    this.setState({ apiList: callAbleApiListName });

    return callAbleApiList;
  }

  /**
   * @param {array} responses of API list
   *
   * @returns {Object} response of API separate by API's name
   * */
  getApiListResponse(responses) {
    const responseByName = {};
    for (let apiListIndex = 0; apiListIndex < this.state.apiList.length; apiListIndex++) {
      responseByName[this.state.apiList[apiListIndex]] = responses[apiListIndex];
    }
    return responseByName;
  }

  /**
   * @param {bool} addVideoSchema should use
   *
   * @returns {boolean} editor is valid.
   */
  isValidForm = async (addVideoSchema) => {
    const schema = Yup.object().shape(this.getSchema(addVideoSchema));

    return await schema.isValid(this.state.post);
  };

  /**
   * @param {Event} e Event object
   * @returns {Object} Fake Event object
   */
  flattenSelectEvent = (e) => {
    return {
      ...e,
      target: { ...e.target, value: e.target.value ? e.target.value.value : '' },
    };
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;
    const checked = e.target.checked;

    this.setState(
      {
        post: {
          ...this.state.post,
          [name]: type === 'checkbox' ? checked : value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm(this.isCategoryInclude('Videos'));
        const errors = await this.isValidSchema(name, value);

        this.formChanged = true;
        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  /**
   * @param {Date} date published_at date
   * @description set published_at property
   * */
  onChangePublishedDate = (date) => {
    this.setState({
      post: {
        ...this.state.post,
        published_at: date,
      },
    });
    this.formChanged = true;
  };

  changeColor = ({ hex }, name) => {
    this.setState({ post: { ...this.state.post, [name]: hex } });
    this.formChanged = true;
  };

  /**
   * @param {Event} e Event data.
   */
  onChangeMultiSelect = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      {
        post: {
          ...this.state.post,
          [name]: value,
        },
      },
      async () => {
        const isValidForm = await this.isValidForm(this.isCategoryInclude('Videos'));

        const { isValid } = isValidForm
          ? { isValid: true }
          : await isValidSchema(urlSchema('video_url'), {
              video_url: this.state.post.video_url,
            });

        const errors = await this.isValidSchema(name, value);

        this.setState({
          isValid: isValidForm,
          errors: { ...errors, video_url: !isValid },
        });

        this.hideFieldsByCategory();
      }
    );
    this.formChanged = true;
  };

  /**
   * @param {string} name field name
   * @param {string} value field value
   *
   * @returns {Object} error object
   * */
  async isValidSchema(name, value) {
    const errors = { ...this.state.errors };

    const { error } = await isValidSchema(
      { [name]: this.getSchema(this.isCategoryInclude('Videos'))[name] },
      {
        [name]: value,
      }
    );
    errors[name] = error;

    return errors;
  }

  /**
   * @param {string} searchedType category type is included
   *
   * @returns {bool} is included
   * */
  isCategoryInclude(searchedType) {
    if (!this.state.post.categories) {
      return false;
    }

    if (this.state.post.categories.length) {
      return !!this.state.post.categories.find((x) => x.label === searchedType);
    } else {
      return this.state.post.categories.label === searchedType;
    }
  }

  /**
   * @description hide unnecessery fields
   * */
  hideFieldsByCategory() {
    if (!this.props.basePath.includes('edge-plus')) {
      return;
    }
    switch (this.state.post.categories.label) {
      case 'Videos': {
        this.setState({
          hiddenFields: [
            ...this.props.hiddenFields,
            'key_facts',
            'top_list',
            'recommendation_section',
          ],
        });
        break;
      }
      case 'Note': {
        this.setState({ hiddenFields: [...this.props.hiddenFields, 'top_list'] });
        break;
      }
      case 'Infographics': {
        this.setState({
          hiddenFields: [
            ...this.props.hiddenFields,
            'key_facts',
            'video_url',
            'video_description',
            'top_list',
            'recommendation_section',
          ],
        });
        break;
      }
      case 'Toplists': {
        this.setState({
          hiddenFields: [
            ...this.props.hiddenFields,
            'key_facts',
            'content',
            'video_url',
            'video_description',
            'recommendation_section',
          ],
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  getIds = (data) => {
    return data ? data.map((item) => item.value) : [];
  };

  /**
   * @param {Object} post to save
   *
   * @returns {array} selected categories id
   * */
  getCategoriesId(post) {
    if (this.props.categoryNoteOrVideo) {
      return [
        this.state.post.video_url ? this.props.categories[0].id : this.props.categories[1].id,
      ];
    } else {
      return post.categories.length
        ? this.getIds(post.categories)
        : post.categories.value
        ? [post.categories.value]
        : [];
    }
  }

  /**
   * @param {Object} post object
   *
   * @returns {Object} categories
   *
   * */
  getListsByExists(post) {
    return {
      categories_id:
        !this.state.hiddenFields.includes('categories') || this.props.categoryNoteOrVideo
          ? this.getCategoriesId(post)
          : null,
      related_companies_id: !this.state.hiddenFields.includes('related_companies')
        ? this.getIds(post.related_companies)
        : null,
      disclaimer_companies_id: !this.state.hiddenFields.includes('disclaimer_companies')
        ? this.getIds(post.disclaimer_companies)
        : null,
      additional_related_companies_id: !this.state.hiddenFields.includes(
        'additional_related_companies'
      )
        ? this.getIds(post.additional_related_companies)
        : null,
      tag_id: !this.state.hiddenFields.includes('tag') ? this.getIds(post.tags) : null,
      geography_id: !this.state.hiddenFields.includes('geography')
        ? this.getIds(post.geography)
        : null,
      region_id: !this.state.hiddenFields.includes('regions') ? this.getIds(post.regions) : null,
      investment_style_id: !this.state.hiddenFields.includes('investment_style')
        ? this.getIds(post.investment_style)
        : null,
      marketcap_id: !this.state.hiddenFields.includes('marketcap')
        ? this.getIds(post.marketcap)
        : null,
      like_questions_id: !this.state.hiddenFields.includes('like_questions')
        ? this.getIds(post.like_questions)
        : null,
    };
  }

  /**
   * @description set sectors to empty array
   *
   * @param {Event} e Event data.
   * @returns {Object} Fake Event object
   * */
  onChangeArticleCompany = async (e) => {
    await this.onChange(this.flattenSelectEvent(e));
    this.setState({
      post: { ...this.state.post, sectors: [], sector_industries: [], entitlements: [] },
      errors: { ...this.state.errors, sectors: {}, sector_industries: {}, entitlements: {} },
    });
  };

  /**
   *
   * @param {boolean} publish immediately
   *
   * @description save form
   */
  save = (publish = false) => {
    const post = JSON.parse(JSON.stringify(this.state.post));
    if (post.generate_pdf) {
      toast.warn(<FormattedMessage id="ADMIN.POSTS.SAVE_INFO" />);
    }

    for (const hiddenField of this.state.hiddenFields) {
      delete post[hiddenField];
    }

    const article_companies_id = [];

    if (post.company_id1) {
      article_companies_id.push(post.company_id1);
    }

    if (post.company_id2) {
      article_companies_id.push(post.company_id2);
    }

    if (post.company_id3) {
      article_companies_id.push(post.company_id3);
    }

    const postToSave = {
      ...post,
      author_ids: post.authors ? this.getIds(post.authors) : null,
      ...this.getListsByExists(post),
      status: publish ? 'active' : 'Draft',
      featured_image_path: this.state.post.featured_image_path,
      sectors:
        post.sectors[0] instanceof Object
          ? post.sectors.map((sector) => sector.value)
          : post.sectors,
      sector_industries:
        post.sector_industries[0] instanceof Object
          ? post.sector_industries.map((sector) => sector.value)
          : post.sector_industries,
      entitlements:
        post.entitlements[0] instanceof Object
          ? post.entitlements.map((entitlement) => entitlement.value)
          : post.entitlements,
      company_id1: post.company_id1 ? post.company_id1 : null,
      company_id2: post.company_id2 ? post.company_id2 : null,
      company_id3: post.company_id3 ? post.company_id3 : null,
      article_companies_id,
      sharepoint_last_sync_at: this.state.outOfSyncData?.sharepoint_file_updated_at || '',
    };

    this.formChanged = false;
    this.setState(
      {
        post: {
          ...postToSave,
          published_at: postToSave.published_at
            ? moment(postToSave.published_at)
            : postToSave.published_at,
        },
        ...(publish ? { saveAndPublishing: true } : { saving: true }),
      },
      async () => {
        postToSave.published_at = post.published_at && setDSTDateString(post.published_at);

        try {
          let response;
          if (typeof postToSave.id !== 'undefined') {
            response = await this.props.modifyPost(postToSave.id, postToSave);
            if (this.state.changedFeaturedImage) {
              response = await this.saveFeatureImage(postToSave.id);
            }
          } else {
            response = await this.props.savePost(postToSave);
            if (this.state.changedFeaturedImage && !!response.id) {
              response = await this.saveFeatureImage(response.id);
            }
            this.props.history.push(`/app/admin/content/posts/${response.id}/edit`);
          }

          toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
          this.setState(
            publish
              ? {
                  saveAndPublishing: false,
                  post: {
                    ...this.state.post,
                    slug: response.slug,
                    id: response.id,
                    media: response.media,
                    featured_image_path: response.featured_image_path,
                    published_at: getDSTDate(response.published_at),
                    downloads: response.downloads,
                  },
                }
              : {
                  saving: false,
                  post: {
                    ...this.state.post,
                    slug: response.slug,
                    id: response.id,
                    media: response.media,
                    featured_image_path: response.featured_image_path,
                    published_at: getDSTDate(response.published_at),
                    downloads: response.downloads,
                  },
                }
          );
        } finally {
          this.setState({ loading: false });
          this.setState({ showSaveAndPublishPopup: false });
          this.setState({ showOutOfSyncPopupSave: false });
          this.setState({ showOutOfSyncPopupSaveAndPublish: false });
        }
      }
    );
  };

  publish = async () => {
    this.setState({ publishing: true });

    if (this.state.post.generate_pdf) {
      toast.warn(<FormattedMessage id="ADMIN.POSTS.PUBLISH_INFO" />);
    }

    try {
      const article_companies_id = [];

      if (this.state.post.company_id1) {
        article_companies_id.push(this.state.post.company_id1);
      }

      if (this.state.post.company_id2) {
        article_companies_id.push(this.state.post.company_id2);
      }

      if (this.state.post.company_id3) {
        article_companies_id.push(this.state.post.company_id3);
      }

      const response = await this.props.modifyPost(this.state.post.id, {
        status: 'active',
        generate_pdf: this.state.post.generate_pdf,
        article_companies_id,
        send_data_to_worldflow: this.state.post.send_data_to_worldflow,
        sectors:
          this.state.post.sectors[0] instanceof Object
            ? this.state.post.sectors.map((sector) => sector.value)
            : this.state.post.sectors,
        sector_industries:
          this.state.post.sector_industries[0] instanceof Object
            ? this.state.post.sector_industries.map((sector) => sector.value)
            : this.state.post.sector_industries,
        entitlements:
          this.state.post.entitlements[0] instanceof Object
            ? this.state.post.entitlements.map((entitlement) => entitlement.value)
            : this.state.post.entitlements,
      });
      this.setState({
        post: {
          ...this.state.post,
          downloads: response.downloads,
        },
      });
      toast.success(<FormattedMessage id="MAIN.PUBLISH_SUCCESS" />);
    } finally {
      this.setState({ publishing: false });
      this.setState({ showPopup: false });
      this.setState({ showOutOfSyncPopupPublish: false });
    }
  };

  addFile = () => {
    const downloads = this.state.post.downloads ? this.state.post.downloads : [];
    const post = {
      ...this.state.post,
      downloads: [...downloads, PostsEditor.getFile()],
    };

    this.setState({ post });
  };

  onChangeFile = (e, index) => {
    const name = e.target.name;
    const validationSchema = {
      [name]: Yup.string().max(
        Env.MAX_TEXT_FIELD_LENGTH,
        `Cannot be longer than ${Env.MAX_TEXT_FIELD_LENGTH} characters.`
      ),
    };
    const post = {
      ...this.state.post,
      downloads: [...this.state.post.downloads],
    };

    post.downloads[index].custom_properties[name] = e.target.value;

    this.setState({ post }, async () => {
      const isValidForm = await this.isValidForm(this.isCategoryInclude('Videos'));
      const { error } = await isValidSchema(
        validationSchema,
        post.downloads[index].custom_properties
      );
      const downloadsErrors = {
        ...this.state.errors.downloads,
        [index]: {
          ...this.state.errors.downloads?.[index],
          [name]: error ? error.message : undefined,
        },
      };
      this.setState({
        isValid: isValidForm,
        errors: { ...this.state.errors, downloads: downloadsErrors },
      });
    });
    this.formChanged = true;
  };

  removeFile = async (index) => {
    const downloads = this.state.post.downloads.map((data) => data);
    const deletedFile = downloads.splice(index, 1);

    if (this.edit && deletedFile[0].id) {
      await this.props.deleteDownloadFile(this.state.post.id, deletedFile[0].id);
    }

    this.setState({ post: { ...this.state.post, downloads } }, async () => {
      const isValidForm = await this.isValidForm(this.isCategoryInclude('Videos'));
      const downloadsErrors = {
        ...this.state.errors.downloads,
        [index]: undefined,
      };
      this.setState({
        isValid: isValidForm,
        errors: { ...this.state.errors, downloads: downloadsErrors },
      });
    });
  };

  addTopList = () => {
    const toplist = this.state.post.toplist ? this.state.post.toplist : [];
    const post = {
      ...this.state.post,
      toplist: [...toplist, PostsEditor.getTopList()],
    };

    this.setState({ post });
  };

  onChangeTopList = (e, index) => {
    const post = {
      ...this.state.post,
      toplist: [...this.state.post.toplist],
    };

    post.toplist[index][e.target.name] = e.target.value;

    this.setState({ post });
    this.formChanged = true;
  };

  removeTopList = (index) => {
    const toplist = this.state.post.toplist.map((data) => data);
    toplist.splice(index, 1);
    this.setState({ post: { ...this.state.post, toplist } });
  };

  onDropImage = async (files, index, type) => {
    if (!!index || (index === 0 && type === 'downloads')) {
      // downloads
      const post = {
        ...this.state.post,
        downloads: [...this.state.post.downloads],
      };

      if (files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          post.downloads[index].data = reader.result;
          post.downloads[index].file_name = files[0].name;
          post.downloads[index].mime_type = files[0].type;
          delete post.downloads[index].id;
          this.setState({ post });
        };
      }
    } else if (!!index || (index === 0 && type === 'toplist')) {
      // topList
      const post = {
        ...this.state.post,
        toplist: [...this.state.post.toplist],
      };

      post.toplist[index].loading = true;
      this.setState({
        post,
      });

      const { path } = await commonFileUpload(files, this.props.commonUploadFile);

      post.toplist[index].logo = path;
      delete post.toplist[index].loading;
      this.setState({ post });
    } else {
      //featured_image
      this.setState({
        post: { ...this.state.post, featured_image_path: files.length > 0 ? files[0] : null },
        changedFeaturedImage: true,
      });
    }
    this.formChanged = true;
  };

  deleteImageFile = async () => {
    this.setState({
      post: { ...this.state.post, featured_image_path: null, media: [] },
    });
  };

  deleteTopListFile = async (index) => {
    const post = {
      ...this.state.post,
      toplist: [...this.state.post.toplist],
    };

    post.toplist[index].logo = null;
    this.setState({ post });
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    const downloads = arrayMove(this.state.post.downloads, oldIndex, newIndex);
    for (let i = 0; i < downloads.length; i++) {
      downloads[i].order_column = i;
    }
    this.setState({
      post: {
        ...this.state.post,
        downloads,
      },
    });
  };

  saveFeatureImage = async (id) => {
    const formData = new FormData();
    formData.append('featured_image', this.state.post.featured_image_path);
    const response = await this.props.saveFeaturedImage(id, formData);
    this.setState({ changedFeaturedImage: false });
    return response;
  };

  setReadyState = (isReady) => {
    this.setState({ isReady });
  };

  isPublishDisabled = () => {
    return (
      !this.state.isValid ||
      !this.state.isReady ||
      !this.state.isSynced ||
      !this.state.post.id ||
      this.state.post.status === 'active'
    );
  };

  /**
   * @param {string} name is name of new tag
   * @description save new tag with proper API
   * */
  saveTag = async ({ value }) => {
    this.setState({ isTagLoading: true });
    const tag = await this.props.saveTag({
      name: value,
      slug: value,
    });
    const tagOptions = JSON.parse(JSON.stringify(this.state.tagOptions));
    const tags = JSON.parse(JSON.stringify(this.state.post.tags));
    tagOptions.push(tag);
    tags.push({
      label: tag.name,
      value: tag.id,
    });
    this.setState({ isTagLoading: false, tagOptions, post: { ...this.state.post, tags } });
  };

  onOpenPopup = () => {
    this.setState({ showPopup: true });
  };

  onClosePopup = () => {
    this.setState({ showPopup: false });
  };

  onOpenSaveAndPublishPopup = () => {
    this.setState({ showSaveAndPublishPopup: true });
  };

  onCloseSaveAndPublishPopup = () => {
    this.setState({ showSaveAndPublishPopup: false });
  };

  syncSharepoint = async () => {
    try {
      this.setState({ syncInProgress: true });
      const res = await this.props.syncSharepoint({
        sharepoint_url: this.state.post.sharepoint_url,
        post_id: parseInt(this.props.match.params.id, 10),
      });
      if (res.status === 'success') {
        this.setState({
          isSynced: true,
          sharepoint_file_updated_at: res.sharepoint_file_updated_at,
          post: {
            ...this.state.post,
            content: res.content,
            investment_thesis: res.investment_thesis,
          },
        });
        if (this.contentRef.current) {
          this.contentRef.current.replace(res.content);
        }
        if (this.investmentThesisRef.current) {
          this.investmentThesisRef.current.replace(res.investment_thesis);
        }
        toast.success(res.messages[0]);
      } else if (res.status === 'warning') {
        res.messages.map((message) => toast.warn(message, { autoClose: Env.LONG_TOAST_DURATION }));
        this.setState({
          isSynced: true,
          sharepoint_file_updated_at: res.sharepoint_file_updated_at,
          post: {
            ...this.state.post,
            content: res.content,
            investment_thesis: res.investment_thesis,
          },
        });
        if (this.contentRef.current) {
          this.contentRef.current.replace(res.content);
        }
        if (this.investmentThesisRef.current) {
          this.investmentThesisRef.current.replace(res.investment_thesis);
        }
      } else {
        res.messages.map((message) => toast.error(message, { autoClose: Env.LONG_TOAST_DURATION }));
      }
    } catch (error) {
      toast.error(<FormattedMessage id="ADMIN.SYNC_ERROR" />);
    }
    this.setState({ syncInProgress: false });
  };

  clearSharepointUrl = () => {
    this.setState({
      post: { ...this.state.post, sharepoint_url: '', sharepoint_last_sync_at: null },
      isSynced: true,
    });
  };

  sharePointOnchange = async (e) => {
    e.persist();
    await this.onChange(e);
    this.setState({ isSynced: e.target.value ? false : true });
  };

  onCloseOutOfSyncPopupSave = () => {
    this.setState({ showOutOfSyncPopupSave: false });
  };

  onCloseOutOfSyncPopupPublish = () => {
    this.setState({ showOutOfSyncPopupPublish: false });
  };

  onCloseOutOfSyncPopupSaveAndPublish = () => {
    this.setState({ showOutOfSyncPopupSaveAndPublish: false });
  };

  checkSync = async (type) => {
    let isOutOfSync = false;

    if (this.state.post.sharepoint_url) {
      const res = await this.props.checkSharepointSync(this.state.post.sharepoint_url);
      const posts = res.posts.map((post) => {
        if (
          post.id === parseInt(this.props.match.params.id, 10) &&
          this.state.sharepoint_file_updated_at
        ) {
          post.last_sync_at = this.state.sharepoint_file_updated_at;
        }
        return post;
      });
      this.setState({ outOfSyncData: res });
      isOutOfSync = posts.some(
        (post) => moment(post.last_sync_at) < moment(res.sharepoint_file_updated_at)
      );
    }

    if (isOutOfSync) {
      switch (type) {
        case 'save':
          this.setState({ showOutOfSyncPopupSave: true });
          break;
        case 'publish':
          this.setState({ showOutOfSyncPopupPublish: true });
          break;
        case 'saveAndPublish':
          this.setState({ showOutOfSyncPopupSaveAndPublish: true });
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case 'save':
          this.save();
          break;
        case 'publish':
          this.publish();
          break;
        case 'saveAndPublish':
          this.save(true);
          break;

        default:
          break;
      }
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const pdf = this.state.post.downloads.find(
      (item) => item.custom_properties.automatically_generated
    );

    const postSharePointSyncDate = this.state.outOfSyncData?.posts.find(
      (post) => post.id === this.state.post.id
    )?.last_sync_at;

    return (
      <div>
        <Prompt when={this.formChanged} history={this.props.history} />
        {!this.state.loading ? (
          <form noValidate>
            <EditorTitle
              viewLink={
                typeof this.state.post.id !== 'undefined'
                  ? `${this.props.basePath}/app/post/${this.state.post.id}/${this.state.post.slug}`
                  : null
              }
              title={
                typeof this.state.post.id !== 'undefined' ? (
                  <FormattedMessage id="ADMIN.POSTS.EDIT_POST" />
                ) : (
                  <FormattedMessage id="ADMIN.POSTS.CREATE_POST" />
                )
              }
              pdf={pdf}>
              <div className="d-inline-flex">
                <Button
                  loading={this.state.saving}
                  type="button"
                  onClick={() => this.checkSync('save')}
                  disabled={!this.state.isValid || !this.state.isReady || !this.state.isSynced}>
                  <FormattedMessage id="ADMIN.SAVE" />
                </Button>

                <Button
                  className="ml-2"
                  loading={this.state.saveAndPublishing}
                  type="button"
                  onClick={this.onOpenSaveAndPublishPopup}
                  disabled={!this.state.isValid || !this.state.isReady || !this.state.isSynced}>
                  <FormattedMessage id="ADMIN.SAVE_AND_PUBLISH" />
                </Button>

                <Button
                  className="ml-2"
                  loading={this.state.publishing}
                  type="button"
                  onClick={this.onOpenPopup}
                  disabled={this.isPublishDisabled()}>
                  <FormattedMessage id="ADMIN.PUBLISH" />
                </Button>
              </div>
            </EditorTitle>
            <Grid fluid className="post-editor editor-content">
              <Row>
                <Col xs={12}>
                  <FieldGroup
                    className="checkbox-form-group text-right"
                    label={<FormattedMessage id="ADMIN.POSTS.SEND_DATA_TO_WORLDFLOW" />}
                    inline
                    type="checkbox"
                    name="send_data_to_worldflow"
                    checked={this.state.post.send_data_to_worldflow}
                    value={this.state.post.send_data_to_worldflow}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12}>
                  <div className="d-flex">
                    <FieldGroup
                      className="w-100"
                      name="sharepoint_url"
                      placeholderId="ADMIN.URL"
                      label={<FormattedMessage id="ADMIN.POSTS.SHAREPOINT_URL" />}
                      error={
                        this.state.errors.sharepoint_url
                          ? this.state.errors.sharepoint_url.message
                          : null
                      }
                      value={this.state.post.sharepoint_url}
                      onChange={this.sharePointOnchange}
                    />
                    <FormGroup className="sync-button-form-group ml-3">
                      <Button
                        type="button"
                        onClick={this.syncSharepoint}
                        loading={this.state.syncInProgress}
                        disabled={!this.state.post.sharepoint_url}>
                        <FormattedMessage id="ADMIN.SYNC" />
                      </Button>
                    </FormGroup>
                    <FormGroup className="sync-button-form-group ml-3">
                      <Button
                        type="button"
                        onClick={this.clearSharepointUrl}
                        disabled={!this.state.post.sharepoint_url}>
                        <FormattedMessage id="ADMIN.POSTS.DELETE_URL" />
                      </Button>
                    </FormGroup>
                  </div>
                </Col>

                <Col xs={12}>
                  <FieldGroup
                    name="title"
                    placeholderId="ADMIN.TITLE"
                    label={<FormattedMessage id="ADMIN.TITLE" />}
                    error={this.state.errors.title ? this.state.errors.title.message : null}
                    value={this.state.post.title}
                    onChange={this.onChange}
                    required
                  />
                </Col>
                {!this.state.hiddenFields.includes('buy_type') ? (
                  <Col xs={12}>
                    <FieldGroup
                      value={this.state.post.buy_type}
                      error={this.state.errors.buy_type ? this.state.errors.buy_type.message : null}
                      label={<FormattedMessage id="MAIN.POST_BUY_TYPE" />}
                      name="buy_type"
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}
                <Col xs={12}>
                  <FieldGroup
                    componentClass="textarea"
                    placeholderId="ADMIN.SUMMARY"
                    label={<FormattedMessage id="ADMIN.SUMMARY" />}
                    name="summary"
                    required
                    error={this.state.errors.summary ? this.state.errors.summary.message : null}
                    value={this.state.post.summary}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12}>
                  <Select
                    name="sectors"
                    label={<FormattedMessage id="ADMIN.SECTORS.TITLE" />}
                    clearable
                    required={
                      !this.state.post.company_id1 &&
                      !this.state.post.company_id2 &&
                      !this.state.post.company_id3
                    }
                    value={this.state.post.sectors}
                    error={this.state.errors.sectors ? this.state.errors.sectors.message : null}
                    touched
                    disabled={
                      this.state.post.company_id1 ||
                      this.state.post.company_id2 ||
                      this.state.post.company_id3
                        ? true
                        : false
                    }
                    multi
                    bottomMargin
                    onChange={this.onChangeMultiSelect}
                    options={
                      this.props.sectors &&
                      this.props.sectors.map((sector) => ({
                        label: sector.name,
                        value: sector.id,
                      }))
                    }
                  />
                </Col>
                <Col xs={12}>
                  <Select
                    name="sector_industries"
                    label={<FormattedMessage id="ADMIN.SECTORS.INDUSTRY_SECTORS" />}
                    clearable
                    required={
                      !this.state.post.company_id1 &&
                      !this.state.post.company_id2 &&
                      !this.state.post.company_id3
                    }
                    value={this.state.post.sector_industries}
                    error={
                      this.state.errors.sector_industries
                        ? this.state.errors.sector_industries.message
                        : null
                    }
                    touched
                    disabled={
                      this.state.post.company_id1 ||
                      this.state.post.company_id2 ||
                      this.state.post.company_id3
                        ? true
                        : false
                    }
                    multi
                    bottomMargin
                    onChange={this.onChangeMultiSelect}
                    options={
                      this.props.sector_industries &&
                      this.props.sector_industries.map((sector) => ({
                        label: sector.name,
                        value: sector.id,
                      }))
                    }
                  />
                </Col>
                <Col xs={12}>
                  <Select
                    name="entitlements"
                    label={<FormattedMessage id="ADMIN.SECTORS.ENTITLEMENTS" />}
                    clearable
                    required={
                      !this.state.post.company_id1 &&
                      !this.state.post.company_id2 &&
                      !this.state.post.company_id3
                    }
                    value={this.state.post.entitlements}
                    error={
                      this.state.errors.entitlements ? this.state.errors.entitlements.message : null
                    }
                    touched
                    disabled={
                      this.state.post.company_id1 ||
                      this.state.post.company_id2 ||
                      this.state.post.company_id3
                        ? true
                        : false
                    }
                    multi
                    bottomMargin
                    onChange={this.onChangeMultiSelect}
                    options={
                      this.props.entitlements &&
                      this.props.entitlements.map((sector) => ({
                        label: sector.name,
                        value: sector.id,
                      }))
                    }
                  />
                </Col>
                <Col xs={12}>
                  <ControlLabel>
                    <FormattedMessage id="ADMIN.POSTS.PUBLISHED_AT" />
                  </ControlLabel>
                  <DateTimePicker
                    selected={this.state.post.published_at}
                    onChange={this.onChangePublishedDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={Env.PUBLISH_DATE_TIME_INTERVAL}
                    dateFormat="LLL"
                    timeCaption="time"
                  />
                </Col>
                {!this.state.hiddenFields.includes('author_id') ? (
                  <Col xs={12}>
                    <Select
                      name="authors"
                      label={<FormattedMessage id="ADMIN.AUTHORS" />}
                      clearable={false}
                      value={this.state.post.authors}
                      error={this.state.errors.authors ? this.state.errors.authors.message : null}
                      required
                      touched
                      multi
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={
                        this.props.employees &&
                        this.props.employees.map((employee) => ({
                          label: formatUsername(employee),
                          value: employee.id,
                        }))
                      }
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('show_author_info') ? (
                  <Col xs={12}>
                    <FieldGroup
                      className="checkbox-form-group"
                      label={<FormattedMessage id="ADMIN.POSTS.SHOW_AUTHOR_INFO" />}
                      inline
                      type="checkbox"
                      name="show_author_info"
                      checked={this.state.post.show_author_info}
                      value={this.state.post.show_author_info}
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('idea_source_id') ? (
                  <Col xs={12}>
                    <Select
                      name="edge_contributor_id"
                      label={<FormattedMessage id="ADMIN.CONTRIBUTOR" />}
                      value={this.state.post.edge_contributor_id}
                      bottomMargin
                      onChange={(e) => this.onChange(this.flattenSelectEvent(e))}
                      options={
                        this.props.contributors &&
                        this.props.contributors.map((contributor) => ({
                          label: formatUsername(contributor),
                          value: contributor.id,
                        }))
                      }
                    />
                  </Col>
                ) : null}
                {!this.state.hiddenFields.includes('company') ? (
                  <Col xs={12}>
                    <FieldGroup
                      value={this.state.post.company}
                      error={this.state.errors.company ? this.state.errors.company.message : null}
                      label={<FormattedMessage id="MAIN.COMPANY" />}
                      name="company"
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}
                {!this.state.hiddenFields.includes('video_url') ? (
                  <Col xs={12}>
                    <FieldGroup
                      name="video_url"
                      placeholderId="ADMIN.COMPANIES.VIDEO_URL"
                      label={<FormattedMessage id="ADMIN.COMPANIES.VIDEO_URL" />}
                      value={this.state.post.video_url}
                      error={
                        this.state.errors.video_url ? this.state.errors.video_url.message : null
                      }
                      onChange={this.onChange}
                      required={this.isCategoryInclude('Videos')}
                    />
                  </Col>
                ) : null}

                {this.state.post.video_url ? (
                  <Col xs={12}>
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.POSTS.VIDEO_TRANSCRIPT" />
                    </ControlLabel>
                    <TextEditor
                      name="video_transcription"
                      className="post-html-content"
                      text={this.state.post.video_transcription}
                      setReadyState={this.setReadyState}
                      handleChange={this.onChange}
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('video_description') ? (
                  <Col xs={12}>
                    <FieldGroup
                      componentClass="textarea"
                      name="video_description"
                      placeholderId="ADMIN.VIDEO_DESCRIPTION"
                      label={<FormattedMessage id="ADMIN.VIDEO_DESCRIPTION" />}
                      value={this.state.post.video_description}
                      error={
                        this.state.errors.video_description
                          ? this.state.errors.video_description.message
                          : null
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}

                {/*Key facts*/}
                {!this.state.hiddenFields.includes('key_facts') ? (
                  <Fragment>
                    <Col xs={12}>
                      <Select
                        name="company_id1"
                        label={<FormattedMessage id="ADMIN.POSTS.ARTICLE_COMPANY" />}
                        value={this.state.post.company_id1}
                        bottomMargin
                        required={
                          this.state.post.key_facts ||
                          this.state.post.company_id2 ||
                          this.state.post.company_id3
                        }
                        onChange={(e) => this.onChangeArticleCompany(e)}
                        options={
                          this.props.companies &&
                          this.props.companies
                            .filter(
                              ({ id }) =>
                                !(
                                  id === this.state.post.company_id2 ||
                                  id === this.state.post.company_id3
                                )
                            )
                            .map((company) => ({
                              label: company.title,
                              value: company.id,
                            }))
                        }
                      />
                    </Col>
                    <KeyFacts
                      onChange={this.onChange}
                      post={this.state.post}
                      hiddenFields={this.props.hiddenFields}
                      errors={this.state.errors}
                      edge={this.props.basePath.includes('edge')}
                    />
                    <Col xs={12}>
                      <Select
                        name="company_id2"
                        label={<FormattedMessage id="ADMIN.POSTS.ARTICLE_COMPANY" />}
                        value={this.state.post.company_id2}
                        bottomMargin
                        required={this.state.post.key_facts_2 || this.state.post.company_id3}
                        onChange={(e) => this.onChangeArticleCompany(e)}
                        options={
                          this.props.companies &&
                          this.props.companies
                            .filter(
                              ({ id }) =>
                                !(
                                  id === this.state.post.company_id1 ||
                                  id === this.state.post.company_id3
                                )
                            )
                            .map((company) => ({
                              label: company.title,
                              value: company.id,
                            }))
                        }
                      />
                    </Col>
                    <KeyFacts
                      onChange={this.onChange}
                      post={this.state.post}
                      hiddenFields={this.props.hiddenFields}
                      errors={this.state.errors}
                      edge={this.props.basePath.includes('edge')}
                      keyFactsKey="_2"
                    />
                    <Col xs={12}>
                      <Select
                        name="company_id3"
                        label={<FormattedMessage id="ADMIN.POSTS.ARTICLE_COMPANY" />}
                        value={this.state.post.company_id3}
                        bottomMargin
                        required={this.state.post.key_facts_3}
                        onChange={(e) => this.onChangeArticleCompany(e)}
                        options={
                          this.props.companies &&
                          this.props.companies
                            .filter(
                              ({ id }) =>
                                !(
                                  id === this.state.post.company_id1 ||
                                  id === this.state.post.company_id2
                                )
                            )
                            .map((company) => ({
                              label: company.title,
                              value: company.id,
                            }))
                        }
                      />
                    </Col>
                    <KeyFacts
                      onChange={this.onChange}
                      post={this.state.post}
                      hiddenFields={this.props.hiddenFields}
                      errors={this.state.errors}
                      edge={this.props.basePath.includes('edge')}
                      keyFactsKey="_3"
                    />
                  </Fragment>
                ) : null}

                {!this.state.hiddenFields.includes('investment_thesis') ? (
                  <Col xs={12}>
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.POSTS.INVESTMENT_THESIS" />
                      <span className="ml-1">
                        <InfoTooltip
                          tooltipText={this.props.intl.formatMessage({
                            id: 'ADMIN.POSTS.PARAGRAPH_INFO',
                          })}
                        />
                      </span>
                    </ControlLabel>
                    <TextEditor
                      name="investment_thesis"
                      className="post-html-content investment_thesis"
                      text={this.state.post.investment_thesis}
                      setReadyState={this.setReadyState}
                      handleChange={this.onChange}
                      refReactSummernote={this.investmentThesisRef}
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('content') ? (
                  <Col xs={12}>
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.POSTS.CONTENT" />
                      <span className="ml-1">
                        <InfoTooltip
                          tooltipText={this.props.intl.formatMessage({
                            id: 'ADMIN.POSTS.PARAGRAPH_INFO',
                          })}
                        />
                      </span>
                    </ControlLabel>
                    <TextEditor
                      name="content"
                      className="post-html-content"
                      text={this.state.post.content}
                      setReadyState={this.setReadyState}
                      handleChange={this.onChange}
                      refReactSummernote={this.contentRef}
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('bonus') ? (
                  <Col xs={12}>
                    <FieldGroup
                      className="checkbox-form-group"
                      label={<FormattedMessage id="ADMIN.POSTS.BONUS" />}
                      inline
                      type="checkbox"
                      name="bonus"
                      checked={this.state.post.bonus}
                      value={this.state.post.bonus}
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}

                {/*Recommendation*/}
                {!this.state.hiddenFields.includes('recommendation_section') ? (
                  <Recommendation
                    onChange={this.onChange}
                    post={this.state.post}
                    errors={this.state.errors}
                  />
                ) : null}

                {!this.state.hiddenFields.includes('downloads') ? (
                  <>
                    <Col xs={12}>
                      <ControlLabel>
                        <FormattedMessage id="ADMIN.POSTS.DOWNLOADS" />
                      </ControlLabel>
                      <Downloads
                        name="downloads"
                        onChange={this.onChangeFile}
                        removeFile={this.removeFile}
                        addFile={this.addFile}
                        onDropImage={this.onDropImage}
                        files={this.state.post.downloads}
                        onDragEnd={this.onDragEnd}
                        errors={this.state.errors.downloads ? this.state.errors.downloads : null}
                      />
                    </Col>
                    <Col xs={12}>
                      <FieldGroup
                        className="checkbox-form-group"
                        label={<FormattedMessage id="ADMIN.POSTS.GENERATE_PDF" />}
                        inline
                        type="checkbox"
                        name="generate_pdf"
                        checked={this.state.post.generate_pdf}
                        value={this.state.post.generate_pdf}
                        onChange={this.onChange}
                      />
                    </Col>
                  </>
                ) : null}

                {/*TODO uncomment if need top lists*/}
                {/*{!this.state.hiddenFields.includes('top_list') ? (*/}
                {/*<Col xs={12}>*/}
                {/*<ControlLabel>*/}
                {/*<FormattedMessage id="ADMIN.POSTS.TOP_LIST" />*/}
                {/*</ControlLabel>*/}
                {/*<TopLists*/}
                {/*name="toplist"*/}
                {/*onChange={this.onChangeTopList}*/}
                {/*removeTopListItem={this.removeTopList}*/}
                {/*addToplistItem={this.addTopList}*/}
                {/*deleteFile={this.deleteTopListFile}*/}
                {/*onDropImage={this.onDropImage}*/}
                {/*topList={this.state.post.toplist}*/}
                {/*/>*/}
                {/*</Col>*/}
                {/*) : null}*/}

                {/*related companies*/}
                {!this.state.hiddenFields.includes('related_companies') ? (
                  <Col xs={12}>
                    <Select
                      name="related_companies"
                      label={<FormattedMessage id="ADMIN.POSTS.RELATED_COMPANIES" />}
                      multi
                      value={this.state.post.related_companies}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={this.props.companies.map(({ title, id }) => ({
                        label: title,
                        value: id,
                      }))}
                    />
                  </Col>
                ) : null}

                {/*disclaimer companies*/}
                {!this.state.hiddenFields.includes('disclaimer_companies') ? (
                  <Col xs={12}>
                    <Select
                      name="disclaimer_companies"
                      label={<FormattedMessage id="ADMIN.POSTS.DISCLAIMER_COMPANIES" />}
                      multi
                      value={this.state.post.disclaimer_companies}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={this.props.companies.map(({ title, id }) => ({
                        label: title,
                        value: id,
                      }))}
                    />
                  </Col>
                ) : null}

                {/*additional related companies*/}
                {!this.state.hiddenFields.includes('additional_related_companies') ? (
                  <Col xs={12}>
                    <Select
                      name="additional_related_companies"
                      label={<FormattedMessage id="ADMIN.POSTS.ADDITIONAL_RELATED_COMPANIES" />}
                      multi
                      value={this.state.post.additional_related_companies}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={this.props.companies.map(({ title, id }) => ({
                        label: title,
                        value: id,
                      }))}
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('recommendation_history') ? (
                  <React.Fragment>
                    <Col xs={12}>
                      <FieldGroup
                        className="checkbox-form-group"
                        label={<FormattedMessage id="ADMIN.POSTS.SHOW_IN_RECOMMENDATION_HISTORY" />}
                        inline
                        type="checkbox"
                        name="recommendation_history"
                        checked={this.state.post.recommendation_history}
                        value={this.state.post.recommendation_history}
                        onChange={this.onChange}
                      />
                    </Col>
                    {this.state.post.recommendation_history ? (
                      <Col xs={12}>
                        <FieldGroup
                          name="recommendation_history_title"
                          placeholderId="ADMIN.POSTS.RECOMMENDATION_HISTORY_TITLE"
                          label={<FormattedMessage id="ADMIN.POSTS.RECOMMENDATION_HISTORY_TITLE" />}
                          value={this.state.post.recommendation_history_title}
                          error={
                            this.state.errors.recommendation_history_title
                              ? this.state.errors.recommendation_history_title.message
                              : null
                          }
                          onChange={this.onChange}
                        />
                      </Col>
                    ) : null}
                  </React.Fragment>
                ) : null}

                {/*categories*/}
                {!this.state.hiddenFields.includes('categories') ? (
                  <Col xs={12}>
                    <Select
                      name="categories"
                      label={<FormattedMessage id="ADMIN.POSTS.CATEGORIES" />}
                      multi={this.props.categoriesMulti}
                      clearable={false}
                      value={this.state.post.categories}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={this.props.categories.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))}
                    />
                  </Col>
                ) : null}

                {/*tags*/}
                {!this.state.hiddenFields.includes('tags') ? (
                  <Col xs={12}>
                    <Select
                      name="tags"
                      label={<FormattedMessage id="ADMIN.POSTS.TAGS" />}
                      multi
                      allowCreate
                      isClearable
                      isLoading={this.state.isTagLoading}
                      isDisabled={this.state.isTagLoading}
                      onNewOptionClick={this.saveTag}
                      value={this.state.post.tags}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={this.state.tagOptions.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))}
                    />
                  </Col>
                ) : null}

                {/*geography*/}
                {!this.state.hiddenFields.includes('geography') ? (
                  <Col xs={12}>
                    <Select
                      name="geography"
                      label={<FormattedMessage id="ADMIN.POSTS.GEOGRAPHY" />}
                      multi
                      value={this.state.post.geography}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={
                        this.props.regions &&
                        this.props.regions.map(({ title, id }) => ({
                          label: title,
                          value: id,
                        }))
                      }
                    />
                  </Col>
                ) : null}

                {/*regions*/}
                {!this.state.hiddenFields.includes('regions') ? (
                  <Col xs={12}>
                    <Select
                      name="region"
                      label={<FormattedMessage id="ADMIN.POSTS.REGION" />}
                      value={this.state.post.region}
                      bottomMargin
                      onChange={(e) => this.onChange(this.flattenSelectEvent(e))}
                      options={
                        this.state.regions &&
                        this.state.regions.map((region) => ({
                          label: region,
                          value: region,
                        }))
                      }
                    />
                  </Col>
                ) : null}

                {/*investment-style*/}
                {!this.state.hiddenFields.includes('investment_style') ? (
                  <Col xs={12}>
                    <Select
                      name="investment_style"
                      label={<FormattedMessage id="ADMIN.POSTS.INVESTMENT_STYLES" />}
                      multi
                      value={this.state.post.investment_style}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={
                        this.props.investmentStyles &&
                        this.props.investmentStyles.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))
                      }
                    />
                  </Col>
                ) : null}

                {/*marketcaps*/}
                {!this.state.hiddenFields.includes('marketcap') ? (
                  <Col xs={12}>
                    <Select
                      name="marketcap"
                      label={<FormattedMessage id="ADMIN.POSTS.MARKETCAPS" />}
                      multi
                      value={this.state.post.marketcap}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={
                        this.props.marketcaps &&
                        this.props.marketcaps.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))
                      }
                    />
                  </Col>
                ) : null}

                {!this.state.hiddenFields.includes('like_questions') ? (
                  <Col xs={12}>
                    <Select
                      name="like_questions"
                      label={<FormattedMessage id="ADMIN.POSTS.QUESTIONS" />}
                      multi
                      clearable={false}
                      value={this.state.post.like_questions}
                      bottomMargin
                      onChange={this.onChangeMultiSelect}
                      options={this.props.questions.map(({ question, id }) => ({
                        label: question,
                        value: id,
                      }))}
                    />
                  </Col>
                ) : null}

                {/*Image Header*/}
                <ImageHeader
                  post={this.state.post}
                  onDropImage={this.onDropImage}
                  deleteFile={this.deleteImageFile}
                  changeColor={this.changeColor}
                />
              </Row>
            </Grid>
          </form>
        ) : null}
        <Modal
          show={this.state.showPopup}
          onHide={this.onClosePopup}
          onSubmit={() => {
            this.onClosePopup();
            this.checkSync('publish');
          }}
          onSecondary={this.onClosePopup}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.YES" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.NO" />,
          }}
          size="normal">
          <FormattedMessage id="ADMIN.PUBLISH_POPUP.QUESTION" />
        </Modal>
        <Modal
          show={this.state.showSaveAndPublishPopup}
          onHide={this.onCloseSaveAndPublishPopup}
          loading={this.state.saveAndPublishing}
          onSubmit={() => {
            this.onCloseSaveAndPublishPopup();
            this.checkSync('saveAndPublish');
          }}
          onSecondary={this.onCloseSaveAndPublishPopup}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.YES" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.NO" />,
          }}
          size="normal">
          <FormattedMessage id="ADMIN.PUBLISH_POPUP.QUESTION" />
        </Modal>
        <Modal
          show={this.state.showOutOfSyncPopupSave}
          onHide={this.onCloseOutOfSyncPopupSave}
          onSubmit={this.onCloseOutOfSyncPopupSave}
          loading={this.state.saving}
          onSecondary={() => {
            this.onCloseOutOfSyncPopupSave();
            this.save();
          }}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.NO" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.YES" />,
          }}
          size="large">
          <SyncPopupContent
            postSharePointSyncDate={postSharePointSyncDate}
            outOfSyncData={this.state.outOfSyncData}
          />
        </Modal>
        <Modal
          show={this.state.showOutOfSyncPopupPublish}
          onHide={this.onCloseOutOfSyncPopupPublish}
          onSubmit={this.onCloseOutOfSyncPopupPublish}
          onSecondary={() => {
            this.onCloseOutOfSyncPopupPublish();
            this.publish();
          }}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.NO" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.YES" />,
          }}
          size="large">
          <SyncPopupContent
            postSharePointSyncDate={postSharePointSyncDate}
            outOfSyncData={this.state.outOfSyncData}
          />
        </Modal>
        <Modal
          show={this.state.showOutOfSyncPopupSaveAndPublish}
          onHide={this.onCloseOutOfSyncPopupSaveAndPublish}
          onSubmit={this.onCloseOutOfSyncPopupSaveAndPublish}
          onSecondary={() => {
            this.onCloseOutOfSyncPopupSaveAndPublish();
            this.save(true);
          }}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.NO" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.PUBLISH_POPUP.YES" />,
          }}
          size="large">
          <SyncPopupContent
            postSharePointSyncDate={postSharePointSyncDate}
            outOfSyncData={this.state.outOfSyncData}
          />
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(PostsEditor));
