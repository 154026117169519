import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, forgotPassword, getProfile } from '../../../../../main/actions/users';
import Login from './Login';

/**
 * @param {Object} state,
 *
 * @returns {Object}
 */
const mapStateToProps = (state) => {
  return {
    profile: state.app.profile || {},
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login, forgotPassword, getProfile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
