import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Row, Col, Grid } from 'react-bootstrap';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withRouter } from 'react-router-dom';

import { SvgIcon, Svg } from 'modules/shared';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPassword';
import { location, redirectLink } from 'common';
import style from './style.scss';
import Env from 'env';

class Login extends Component {
  static propTypes = {
    site: PropTypes.string,
    login: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    showForm: PropTypes.bool,
    showLoginForm: PropTypes.func,
    profile: PropTypes.object,
    location,
  };

  state = {
    showForm: true,
    showForgotPassword: false,
    redirectUrl: null,
  };

  /**
   * @description set redirectUrl
   * */
  componentDidMount() {
    this.setState({
      redirectUrl: redirectLink(this.props.location),
    });
  }

  toggleForgotPasswordForm = () => {
    this.setState((prevState) => ({ showForgotPassword: !prevState.showForgotPassword }));
  };

  forgotPassword = async (credentials) => {
    await this.props.forgotPassword(credentials);

    toast.success(
      <FormattedMessage id="MAIN.FORGOT_PASSWORD_SUCCESS" values={{ email: credentials.email }} />,
      {
        className: 'top',
      }
    );
    this.toggleForgotPasswordForm();
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="login-container-base">
        <FormattedMessage id="MAIN.LOGIN">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <Grid>
          <Row>
            <Col md={12}>
              <div className={classNames('login active')}>
                <div className={classNames('circle-wrapper active')}>
                  <div className="content">
                    {this.state.showForgotPassword && (
                      <div className="back large" onClick={this.toggleForgotPasswordForm}>
                        <SvgIcon name="left-arrow" width={14} />
                      </div>
                    )}
                    <div className="container">
                      <div className="title">
                        <NavLink to={Env.SITE_URL}>
                          <Svg name={'the-analyst-logo-light'} width={14} />
                        </NavLink>
                      </div>
                      {!this.state.showForgotPassword && (
                        <LoginForm
                          login={this.props.login}
                          toggleForgotPasswordForm={this.toggleForgotPasswordForm}
                          loginRedirect={this.state.redirectUrl || '/app'}
                        />
                      )}
                      {this.state.showForgotPassword && (
                        <ForgotPasswordForm
                          site={Env.ANALYST_BASE_PATH}
                          back={this.toggleForgotPasswordForm}
                          forgotPassword={this.forgotPassword}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(Login));
