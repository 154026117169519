import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { stringify, parse } from 'common/queryString';

import { PostsList, PostViewFilter, PostsQueryTopic } from 'modules/shared';

class PostViewContainer extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getPostsPage: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    setEditItem: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    defaultImage: PropTypes.string.isRequired,
    titleId: PropTypes.string,
    title: PropTypes.string,
    defaultParams: PropTypes.object,
    intl: PropTypes.object,
  };

  static defaultProps = {
    titleId: 'MAIN.RESEARCH',
    defaultParams: {
      nopaginate: false,
    },
  };

  state = {
    loading: true,
    fetching: false,
    posts: [],
    pagingParams: {},
  };

  /**
   * @description Gets the data for the page.
   */
  async componentDidMount() {
    this.props.setEditItem({
      text: this.props.intl.formatMessage({ id: 'ANALYST.EDIT_POSTS' }),
      link: `/app/admin/content/posts`,
    });
    this.props.showLoader();

    const queryParams = this.initFilter();
    const postResponse = await this.props.getPostsPage({
      ...this.props.defaultParams,
      ...queryParams,
    });
    const { data: posts = [], ...pagingParams } = postResponse ? postResponse : {};

    this.setState({ loading: false, posts, pagingParams });
    this.props.hideLoader();
  }

  initFilter() {
    const queryParams = parse(this.props.location.search);
    if (!queryParams.topic) {
      queryParams.topic = PostsQueryTopic.ALL_RESEARCH;
      this.props.history.replace(`${this.props.location.pathname}?${stringify(queryParams)}`);
    }

    return queryParams;
  }

  fetchDataWhenPaging = async () => {
    this.fetchData(null, this.state.pagingParams.current_page + 1);
  };

  fetchData = async (filter, page) => {
    const queryParams = parse(this.props.location.search);

    if (filter) {
      queryParams[filter.key] = filter.value;
    }

    this.setState({ fetching: true, loading: !!filter });
    const { data: posts = [], ...pagingParams } = await this.props.getPostsPage({
      ...this.props.defaultParams,
      page,
      ...queryParams,
    });
    if (filter) {
      this.setState({ fetching: false, posts, pagingParams, loading: false });
    } else {
      this.setState({ fetching: false, posts: [...this.state.posts, ...posts], pagingParams });
    }
  };

  afterBookmark = (posts) => {
    this.setState({ posts });
  };

  /**
   * @returns {JSX.Element}
   */

  render() {
    const { posts, loading, fetching } = this.state;
    const { basePath } = this.props;

    return (
      <div>
        {!this.props.title ? (
          <FormattedMessage id={this.props.titleId}>
            {(title) => (
              <Helmet defer={false}>
                <title>{title}</title>
              </Helmet>
            )}
          </FormattedMessage>
        ) : (
          <Helmet defer={false}>
            <title>{this.props.title}</title>
          </Helmet>
        )}

        <PostViewFilter onSelected={this.fetchData} />

        <PostsList
          posts={posts}
          loading={loading}
          emptyMessage={<FormattedMessage id="MAIN.POST_EMPTY" />}
          bookmarkPost={this.props.bookmarkPost}
          deleteBookmark={this.props.deleteBookmark}
          afterBookmark={this.afterBookmark}
          hasElements={this.state.pagingParams.current_page < this.state.pagingParams.last_page}
          fetching={fetching}
          basePath={basePath}
          fetchData={this.fetchDataWhenPaging}
          defaultImage={this.props.defaultImage}
        />
      </div>
    );
  }
}

export default injectIntl(PostViewContainer);
