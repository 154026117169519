import React from 'react';
import PropTypes from 'prop-types';
import { withFormikValidation } from 'common/propTypes';
import { FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { TextInput, Button } from 'modules/shared';

/**
 * @param {Object} Props Injected Form Props.
 * @returns {JSX.Element}
 */
const InnerForgotPasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isValid,
  back,
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate className="forgot-password-form">
      <div className="forgot-title">
        <FormattedMessage id="MAIN.FORGOT_PASSWORD" />
      </div>
      <TextInput
        type="email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        placeholderId="MAIN.EMAIL_ADDRESS"
        touched={touched.email}
        error={errors.email}
      />
      <Button type="button" onClick={handleSubmit} disabled={isSubmitting || !isValid}>
        <FormattedMessage id="MAIN.SEND" />
      </Button>
      <div className="d-flex justify-center login-nav-container">
        <div className="redirect-to-form back-text" onClick={back}>
          <FormattedMessage id="MAIN.BACK" />
        </div>
      </div>
    </form>
  );
};

InnerForgotPasswordForm.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  toggleForgotPasswordForm: PropTypes.func.isRequired,
  ...withFormikValidation,
};

const ForgotPasswordForm = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true);
    try {
      await props.forgotPassword({ email: values.email, site: props.site });
    } catch (e) {
      setErrors({ forgotPassword: e });
    }
  },
})(InnerForgotPasswordForm);

export default ForgotPasswordForm;
