import React from 'react';
import classNames from 'classnames';

import RecommendationDrawer from './RecommendationDrawer';
import { Buys } from 'common';

interface DesktopDrawerMenuProps {
  className?: string;
  open?: boolean;
  toggleDrawer: () => void;
  title?: string | React.ReactNode;
  icon: React.ReactNode;
  drawerHeight?: number;
  companies?: object;
  basePath: string;
}

const DesktopDrawerMenu = ({
  className,
  open,
  toggleDrawer,
  title,
  icon,
  drawerHeight,
  companies,
  basePath,
}: DesktopDrawerMenuProps) => {
  return [
    <div
      key="item"
      className={classNames(className, 'sidebar-item', {
        active: open,
      })}
      onClick={toggleDrawer}>
      <div className="content">
        <div className="module-icon-container">{icon}</div>
        <div className="title">{title}</div>
        <i className="expand fa fa-caret-right" />
      </div>
    </div>,
    <RecommendationDrawer
      className="first-drawer"
      basePath={basePath}
      key="buy"
      style={{ maxHeight: drawerHeight }}
      title="Long"
      companies={companies[Buys.LONG]}
      open={open}
      toggleDrawer={toggleDrawer}
      recommendationType={Buys.LONG}
    />,
    <RecommendationDrawer
      className="second-drawer"
      basePath={basePath}
      key="short"
      style={{ maxHeight: drawerHeight }}
      title="Short"
      companies={companies[Buys.SHORT]}
      open={open}
      toggleDrawer={toggleDrawer}
      recommendationType={Buys.SHORT}
    />,
  ];
};

export default DesktopDrawerMenu;
