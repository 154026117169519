import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ControlLabel } from 'react-bootstrap';
import classNames from 'classnames';
import style from './DateTimePicker.scss';

/**
 * @returns {JSX.Element} - DatePicker component
 */
const DateTimePicker = ({
  selected,
  onChange,
  label,
  className,
  touched,
  error,
  inlineError,
  ...props
}) => {
  selected = typeof selected === 'string' ? moment(selected) : selected;
  return (
    <div className={classNames(className, touched && error ? 'datepicker-error' : null)}>
      {label ? <ControlLabel>{label}</ControlLabel> : null}
      <DatePicker selected={selected} onChange={onChange} error {...props} />
      {inlineError ? (
        <div className="datepicker-error-message">{touched && error ? error : null}</div>
      ) : null}
    </div>
  );
};

DateTimePicker.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  inlineError: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withStyles(style)(DateTimePicker);
