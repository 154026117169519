import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SafeAnchor } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { splitBsPropsAndOmit, prefix } from 'react-bootstrap/lib/utils/bootstrapUtils';
import createChainedFunction from 'react-bootstrap/lib//utils/createChainedFunction';

export default class MenuItem extends Component {
  static propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    divider: PropTypes.bool,
    eventKey: PropTypes.any,
    header: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    external: PropTypes.bool,
  };

  handleClick = (event) => {
    const { href, disabled, onSelect, eventKey } = this.props;

    if (!href || disabled) {
      event.preventDefault();
    }

    if (disabled) {
      return;
    }

    if (onSelect) {
      onSelect(eventKey, event);
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      active,
      disabled,
      divider,
      header,
      onClick,
      className,
      style,
      external,
      ...props
    } = this.props;

    const [bsProps, elementProps] = splitBsPropsAndOmit(props, ['eventKey', 'onSelect']);

    if (divider) {
      // Forcibly blank out the children; separators shouldn't render any.
      elementProps.children = undefined;

      return (
        <li
          {...elementProps}
          role="separator"
          className={classNames(className, 'divider')}
          style={style}
        />
      );
    }

    if (header) {
      return (
        <li
          {...elementProps}
          role="heading"
          className={classNames(className, prefix(bsProps, 'header'))}
          style={style}
        />
      );
    }

    return (
      <li role="presentation" className={classNames(className, { active, disabled })} style={style}>
        {elementProps.href ? (
          external ? (
            <a href={elementProps.href}>{elementProps.children}</a>
          ) : (
            <NavLink
              to={elementProps.href}
              exact
              onClick={createChainedFunction(onClick, this.handleClick)}>
              {elementProps.children}
            </NavLink>
          )
        ) : (
          <SafeAnchor
            {...elementProps}
            role="menuitem"
            tabIndex="-1"
            onClick={createChainedFunction(onClick, this.handleClick)}
          />
        )}
      </li>
    );
  }
}
