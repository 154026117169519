import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './InfoTooltip.scss';

/**
 * @param {Object} container Container component.
 * @param {JSX.Element | string} tooltipText Text to show in tooltip.
 * @param {Object} props Other injected props.
 * @returns {JSX.Element}
 */
const InfoTooltip = ({ container, tooltipText, ...props }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="tooltip" className="info-tooltip">
          {tooltipText}
        </Tooltip>
      }
      container={container}
      {...props}>
      <i className="icon-info-outline small" />
    </OverlayTrigger>
  );
};

InfoTooltip.propTypes = {
  container: PropTypes.object.isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default withStyles(styles)(InfoTooltip);
