import React, { Component } from 'react';
import { Col, FormControl } from 'react-bootstrap';
import styles from './ModelPortfolioCompany.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { Delete, DragHandle, AddPlaceholder, Select } from 'modules/admin/shared';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { OLD_COLUMN_NUMBER } from './ModelIdeas';

const SortableBlock = SortableElement(
  ({ item, onChangeCompanies, companyIndex, rate, removeCompany, companiesList }) => {
    // Use custom at select options if needed later (at ModelIdeas.jsx line 50.)
    /**
     * @returns {boolean} show rate select col
     * */
    const showRateSelect = () => {
      return rate.value === 'custom';
    };

    /**
     * @param {Event} e Event object
     * @returns {Object} Fake Event object
     */
    const flattenSelectEvent = (e) => {
      return {
        ...e,
        target: {
          ...e.target,
          value: e.target.value ? companiesList.find((x) => x.id === e.target.value.value) : '',
        },
      };
    };

    return (
      <Col className="model-portfolio-company-item">
        <Col xs={2} sm={2}>
          <DragHandle />
        </Col>
        <Col xs={showRateSelect() ? 5 : 8} sm={showRateSelect() ? 5 : 8}>
          <Select
            name="company"
            clearable={false}
            value={item.company.id}
            onChange={(e) => onChangeCompanies(flattenSelectEvent(e), companyIndex)}
            options={companiesList.map((company) => ({
              label: company.title,
              value: company.id,
            }))}
            touched
            error={!item.company ? 'Company is required!' : null}
          />
        </Col>
        {showRateSelect() && (
          <Col xs={3} sm={3}>
            <FormControl
              id="formControlsText"
              type="number"
              label="value"
              name="value"
              value={item.value}
              onChange={(event) => onChangeCompanies(event, companyIndex)}
            />
          </Col>
        )}
        <Col xs={2} sm={2}>
          <Delete onSubmit={() => removeCompany(companyIndex)} />
        </Col>
      </Col>
    );
  }
);

const SortableBlockContainer = SortableContainer(
  ({ companies, onChangeCompanies, removeCompany, rate, rowIndex, columnIndex, companiesList }) => {
    return (
      <div>
        {companies &&
          companies.map((item, index) => (
            <SortableBlock
              collection={`${rowIndex}-${columnIndex}`}
              item={item}
              key={index}
              index={index}
              onChangeCompanies={onChangeCompanies}
              companiesList={companiesList}
              companyIndex={index}
              rate={rate}
              removeCompany={removeCompany}
            />
          ))}
      </div>
    );
  }
);

class ModelPortfolioCompany extends Component {
  static propTypes = {
    rate: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    onChangeCompanies: PropTypes.func.isRequired,
    addCompany: PropTypes.func.isRequired,
    removeCompany: PropTypes.func.isRequired,
    columnName: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    companiesList: PropTypes.array.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    colNumber: PropTypes.number.isRequired,
  };

  /**
   * @returns {boolean} show rate select col
   * */
  showRateSelect() {
    return this.props.rate.value === 'custom';
  }

  /**
   * @param {string} props.type - define drag and drop type.
   * @param {Object} props.column - contain data of column.
   * @param {Function} props.onChangeCompanies - onChange event handler for company changes.
   * @param {Function} props.addCompany - onChange event handler for add new company.
   * @param {Function} props.removeCompany - onChange event handler for remove company.
   *
   * @returns {JSX.Element} ModelPortfolioCompany component to add/edit company by rating.
   */
  render() {
    return (
      <Col
        xs={12}
        sm={12}
        md={6}
        lg={this.props.colNumber > OLD_COLUMN_NUMBER ? 3 : 4}
        className="model-portfolio-add-company">
        <strong>{this.props.columnName}</strong>
        <Col className="d-flex w-100 mt-3">
          <Col xs={2} sm={2} />
          <Col xs={this.showRateSelect() ? 5 : 8} sm={this.showRateSelect() ? 5 : 8}>
            <strong>Company</strong>
          </Col>
          {this.showRateSelect() ? (
            <Col xs={3} sm={3}>
              <strong>Value</strong>
            </Col>
          ) : null}
          <Col xs={2} sm={2} />
        </Col>
        <SortableBlockContainer
          helperClass="model-portfolio-company-item"
          companies={this.props.column.companies}
          onChangeCompanies={this.props.onChangeCompanies}
          removeCompany={this.props.removeCompany}
          rate={this.props.rate}
          rowIndex={this.props.rowIndex}
          companiesList={this.props.companiesList}
          columnIndex={this.props.columnIndex}
          onSortEnd={this.props.onDragEnd}
          axis="xy"
          useDragHandle
          delay={100}
        />
        <Col>
          <AddPlaceholder inverse onClick={this.props.addCompany} />
        </Col>
      </Col>
    );
  }
}

export default withStyles(styles)(ModelPortfolioCompany);
