import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { match, history } from 'common';
import { EditorTitle, Button, FieldGroup } from 'modules/admin/shared';
import { isValidSchema, textSchema } from 'common/shemaValidator';

class BaseDataEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getBaseData: PropTypes.func.isRequired,
    saveBaseData: PropTypes.func.isRequired,
    modifyBaseData: PropTypes.func.isRequired,
    createLabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    editLabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    basePath: PropTypes.string.isRequired,
    hiddenFields: PropTypes.array,
    match: match.isRequired,
    history: history.isRequired,
  };

  static defaultProps = {
    hiddenFields: ['name'],
  };

  edit = !!this.props.match.params.id;
  state = {
    baseData: {
      name: '',
      title: '',
      slug: '',
      description: '',
      meta_title: '',
      meta_description: '',
      keywords: '',
    },
    errors: {},
    isSubmitting: false,
    loading: true,
    isValid: false,
  };

  schema = {
    ...textSchema(this.getTitleKey(), { required: true }),
    ...textSchema('slug', { required: !this.props.hiddenFields.includes('slug') }),
    ...textSchema('description', { required: !this.props.hiddenFields.includes('description') }),
    ...textSchema('meta_description'),
    ...textSchema('meta_title'),
    ...textSchema('keywords'),
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();

    if (this.edit) {
      const baseData = await this.props.getBaseData(this.props.match.params.id);
      const isValid = await this.isValidForm(baseData);
      const state = {
        loading: false,
        baseData: {
          ...baseData,
        },
        isValid,
      };
      this.setState(state);
    } else {
      this.setState({ loading: false });
    }
    this.props.hideLoader();
  }

  /**
   * @description save baseData changes
   */
  save = async () => {
    this.setState({ isSubmitting: true });

    const baseData = { ...this.state.baseData };
    for (const key of this.props.hiddenFields) {
      delete baseData[key];
    }

    try {
      if (typeof baseData.id !== 'undefined') {
        await this.props.modifyBaseData(baseData.id, baseData);
      } else {
        const { id } = await this.props.saveBaseData(baseData);
        this.setState({ baseData: { ...this.state.baseData, id } });
        this.props.history.push(`/app/admin/${this.getBasePath()}/${id}/edit`);
      }
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  /**
   * @returns {string} base path
   * */
  getBasePath() {
    if (
      !this.props.basePath.includes('analyst') &&
      !this.props.basePath.includes('edge') &&
      !this.props.basePath.includes('edge-plus')
    ) {
      return this.props.basePath;
    } else {
      return `analyst${this.props.basePath}`;
    }
  }

  /**
   * @returns {boolean} editor is valid.
   * @param {Object} baseData Data object.
   */
  isValidForm = async (baseData = this.state.baseData) => {
    const schema = Yup.object().shape(this.schema);

    return await schema.isValid(baseData);
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      {
        baseData: {
          ...this.state.baseData,
          [name]: value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm();
        const { error } = await isValidSchema(
          { [name]: this.schema[name] },
          {
            [name]: value,
          }
        );
        errors[name] = error;

        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  /**
   * @returns {string} key of title
   * */
  getTitleKey() {
    return this.props.hiddenFields.includes('name') ? 'title' : 'name';
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { baseData, errors, loading, isSubmitting } = this.state;
    return (
      <div>
        {!loading ? (
          <form noValidate>
            <EditorTitle
              title={
                typeof this.state.baseData.id !== 'undefined'
                  ? this.props.editLabel
                  : this.props.createLabel
              }>
              <Button
                loading={isSubmitting}
                onClick={this.save}
                type="button"
                disabled={!this.state.isValid || isSubmitting}>
                <FormattedMessage id="ADMIN.SAVE" />
              </Button>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name={this.getTitleKey()}
                    className="touched"
                    placeholderId="ADMIN.NAME"
                    label={<FormattedMessage id="ADMIN.NAME" />}
                    error={errors[this.getTitleKey()] ? errors[this.getTitleKey()].message : null}
                    value={baseData[this.getTitleKey()]}
                    onChange={this.onChange}
                    required
                  />
                </Col>
                {!this.props.hiddenFields.includes('slug') ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="slug"
                      className="touched"
                      placeholderId="ADMIN.SLUG"
                      label={<FormattedMessage id="ADMIN.SLUG" />}
                      error={errors.slug ? errors.slug.message : null}
                      value={baseData.slug}
                      onChange={this.onChange}
                      required
                    />
                  </Col>
                ) : null}
                {!this.props.hiddenFields.includes('description') ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="description"
                      componentClass="textarea"
                      className="touched"
                      placeholderId="ADMIN.DESCRIPTION"
                      label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
                      error={errors.description ? errors.description.message : null}
                      value={baseData.description}
                      onChange={this.onChange}
                      required
                    />
                  </Col>
                ) : null}
                {!this.props.hiddenFields.includes('keywords') ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="keywords"
                      placeholderId="ADMIN.KEYWORDS"
                      label={<FormattedMessage id="ADMIN.KEYWORDS" />}
                      error={errors.keywords ? errors.keywords.message : null}
                      value={baseData.keywords}
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}
                {!this.props.hiddenFields.includes('meta_title') ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="meta_title"
                      placeholderId="ADMIN.META_TITLE"
                      label={<FormattedMessage id="ADMIN.META_TITLE" />}
                      error={errors.meta_title ? errors.meta_title.message : null}
                      value={baseData.meta_title}
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}
                {!this.props.hiddenFields.includes('meta_description') ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="meta_description"
                      placeholderId="ADMIN.META_DESCRIPTION"
                      label={<FormattedMessage id="ADMIN.META_DESCRIPTION" />}
                      error={errors.meta_description ? errors.meta_description.message : null}
                      value={baseData.meta_description}
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}
              </Row>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

export default BaseDataEditor;
