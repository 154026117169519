import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './Prompt.scss';
import { Prompt as ReactRouterPrompt } from 'react-router';
import { Modal } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import { history } from 'common';

class Prompt extends Component {
  static propTypes = {
    when: PropTypes.bool.isRequired,
    message: PropTypes.node,
    history,
  };

  state = {
    show: false,
    answer: false,
    nextPath: '',
  };

  /**
   * @param {bool} answer value
   * @description Hides the modal.
   */
  handleClose = (answer) => {
    this.setState({ show: false, answer }, () => {
      if (answer) {
        this.props.history.push(this.state.nextPath);
      }
    });
  };

  /**
   * @param {Object} location value
   * @description Shows the modal.
   */
  handleShow = (location) => {
    this.setState({
      show: true,
      nextPath:
        location.pathname === '/app/admin/content/posts/new'
          ? '/app/admin/content/posts'
          : location.pathname,
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { when, message } = this.props;
    return (
      <div>
        <ReactRouterPrompt
          when={when}
          message={(location) => {
            this.handleShow(location);
            return this.state.answer;
          }}
        />
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose(false)}
          onSubmit={() => this.handleClose(false)}
          onSecondary={() => this.handleClose(true)}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.PROMPT.STAY" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.PROMPT.LEAVE" />,
          }}>
          {message ? message : <FormattedMessage id="ADMIN.PROMPT.QUESTION" />}
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(Prompt);
