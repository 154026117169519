import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PostsEditor } from 'modules/admin/shared';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getEmployees } from 'modules/main/actions/users';
import {
  getAdminPost,
  savePost,
  modifyPost,
  saveFeaturedImage,
  saveContentImage,
  deleteDownloadFile,
  syncSharepoint,
  checkSharepointSync,
} from 'modules/analyst/actions/posts';
import { getQuestions } from 'modules/analyst/actions/questions';
import { match, history } from 'common';
import { getAdminCompanies } from 'modules/analyst/actions/companies';
import { getRegions } from 'modules/analyst/actions/regions';
import { getCategories } from 'modules/analyst/actions/categories';
import { getTags, saveTag } from 'modules/analyst/actions/tags';
import { getInvestmentStyles } from 'modules/analyst/actions/investment-style';
import { getMarketcaps } from 'modules/analyst/actions/marketcap';
import { getSectors, getIndustrySectors, getEntitlements } from 'modules/analyst/actions/sectors';

class AnalystPostsEditor extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    employees: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    sectors: PropTypes.array.isRequired,
    sector_industries: PropTypes.array.isRequired,
    entitlements: PropTypes.array.isRequired,
    investmentStyles: PropTypes.array.isRequired,
    questions: PropTypes.array.isRequired,
    marketcaps: PropTypes.array,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getAdminPost: PropTypes.func.isRequired,
    savePost: PropTypes.func.isRequired,
    modifyPost: PropTypes.func.isRequired,
    saveFeaturedImage: PropTypes.func.isRequired,
    saveContentImage: PropTypes.func.isRequired,
    deleteDownloadFile: PropTypes.func.isRequired,
    getEmployees: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    getQuestions: PropTypes.func.isRequired,
    getAdminCompanies: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getSectors: PropTypes.func.isRequired,
    getIndustrySectors: PropTypes.func.isRequired,
    getEntitlements: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    saveTag: PropTypes.func.isRequired,
    getInvestmentStyles: PropTypes.func.isRequired,
    getMarketcaps: PropTypes.func.isRequired,
    systemSettings: PropTypes.object.isRequired,
    basePath: PropTypes.string,
    match: match.isRequired,
    history: history.isRequired,
    syncSharepoint: PropTypes.func.isRequired,
    checkSharepointSync: PropTypes.func.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <PostsEditor
        companies={this.props.companies}
        categories={this.props.categories}
        employees={this.props.employees}
        regions={this.props.regions}
        sectors={this.props.sectors}
        sector_industries={this.props.sector_industries}
        entitlements={this.props.entitlements}
        tags={this.props.tags}
        investmentStyles={this.props.investmentStyles}
        questions={this.props.questions}
        marketcaps={this.props.marketcaps}
        showLoader={this.props.showLoader}
        hideLoader={this.props.hideLoader}
        getAdminPost={this.props.getAdminPost}
        savePost={this.props.savePost}
        modifyPost={this.props.modifyPost}
        saveFeaturedImage={this.props.saveFeaturedImage}
        saveContentImage={this.props.saveContentImage}
        deleteDownloadFile={this.props.deleteDownloadFile}
        getEmployees={this.props.getEmployees}
        getRegions={this.props.getRegions}
        getCompanies={this.props.getAdminCompanies}
        getCategories={this.props.getCategories}
        getSectors={this.props.getSectors}
        getIndustrySectors={this.props.getIndustrySectors}
        getEntitlements={this.props.getEntitlements}
        getQuestions={this.props.getQuestions}
        getTags={this.props.getTags}
        saveTag={this.props.saveTag}
        getInvestmentStyles={this.props.getInvestmentStyles}
        getMarketcaps={this.props.getMarketcaps}
        basePath={this.props.basePath}
        match={this.props.match}
        history={this.props.history}
        hiddenFields={[
          'idea_source_id',
          'video_description',
          'top_list',
          'recommendation_section',
          'shares_out',
          'bonus',
          'company',
          'recommendation_history',
          'geography',
        ]}
        categoriesMulti={true}
        systemSettings={this.props.systemSettings}
        syncSharepoint={this.props.syncSharepoint}
        checkSharepointSync={this.props.checkSharepointSync}
      />
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app, analyst }) => {
  return {
    employees: app.employees,
    regions: analyst.geographies,
    companies: analyst.adminCompanies,
    categories: analyst.categories,
    tags: analyst.tags,
    investmentStyles: analyst.investmentStyles,
    marketcaps: analyst.marketcap,
    questions: analyst.questions,
    systemSettings: app.systemSettings.all,
    sectors: analyst.sectors,
    sector_industries: analyst.sector_industries,
    entitlements: analyst.entitlements,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getAdminPost,
      savePost,
      modifyPost,
      saveFeaturedImage,
      saveContentImage,
      deleteDownloadFile,
      getEmployees,
      getAdminCompanies,
      getCategories,
      getQuestions,
      getTags,
      saveTag,
      getInvestmentStyles,
      getMarketcaps,
      getRegions,
      getSectors,
      getIndustrySectors,
      getEntitlements,
      syncSharepoint,
      checkSharepointSync,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalystPostsEditor);
