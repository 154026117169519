import * as Yup from 'yup';
import Env from 'env';

/**
 * @param {Object} schema validation schema.
 * @param {Object} data data
 *
 * @returns {boolean} is data valid on provided schema
 *
 * */
export const isValidSchema = async (schema, data) => {
  try {
    await Yup.object()
      .shape({ ...schema })
      .validate(data);
    return { isValid: true };
  } catch (error) {
    return { isValid: false, error };
  }
};

/**
 * @param {Object} schema validation schema.
 * @param {Object} data data
 *
 * @returns {boolean} is data valid on provided schema
 *
 * */
export const validateSchema = async (schema, data) => {
  return await Yup.object()
    .shape({ ...schema })
    .validate(data);
};

/**
 * @param {string} key Schema attribute.
 * @param {boolean} required Is the field required.
 * @param {boolean} max length of array.
 * @returns {Object}
 */
export const arraySchema = (key, { required, max } = {}) => {
  const schema = Yup.array();
  const maxValidationLabel = 'Cannot be more than ${max} items.';

  return {
    [key]: required
      ? max
        ? schema.required('Required field').max(max, maxValidationLabel)
        : schema.required()
      : max
      ? schema.nullable().max(max, maxValidationLabel)
      : schema.nullable(),
  };
};

/**
 * @param {string} key Schema attribute.
 * @param {Object} [options] Validation options
 * @param {boolean} [options.required] Is the field required.
 * @param {boolean} [options.max] length of text.
 * @returns {Object}
 */
export const textSchema = (key, { required, max } = {}) => {
  const schema = Yup.string()
    .trim()
    .max(max ? max : Env.MAX_TEXT_FIELD_LENGTH, 'Cannot be longer than ${max} characters.');

  return {
    [key]: required ? schema.required('Required field') : schema.nullable(),
  };
};

/**
 * @param {string} key Schema attribute.
 * @param {Object} [options] Validation options
 * @param {boolean} [options.required] Is the field required.
 * @returns {Object}
 */
export const numberSchema = (key, { required, min, max } = {}) => {
  let schema = Yup.string();

  if (min !== undefined) {
    schema = schema.min(min, `Must be at least ${min} characters`);
  }

  if (max !== undefined) {
    schema = schema.max(max, `Must be at most ${max} characters`);
  }

  schema = schema.matches(Env.NUMBER_PATTERN, 'Please provide a number!');

  return {
    [key]: required ? schema.required('Required field') : schema.nullable(),
  };
};

/**
 * @param {string} key Schema attribute.
 * @param {boolean} required Is the field required.
 * @param {string} pattern to check value.
 * @returns {Object}
 */
export const dateSchema = (key, { required } = {}) => {
  const schema = Yup.string();

  return {
    [key]: required ? schema.required('Required field') : schema.nullable(),
  };
};

/**
 * @param {string} key Schema attribute.
 * @returns {Object}
 */
export const emailSchema = (key) => ({
  [key]: Yup.string()
    .email()
    .max(Env.MAX_TEXT_FIELD_LENGTH, 'Cannot be longer than ${max} characters.')
    .required('Required field'),
});

/**
 * @param {string} key Schema attribute.
 * @param {boolean} required Is the field required.
 * @returns {Object}
 */
export const phoneSchema = (key, { required = false } = {}) => {
  const schema = Yup.string()
    .max(Env.MAX_TEXT_FIELD_LENGTH, 'Cannot be longer than ${max} characters.')
    .matches(Env.PHONE_PATTERN, 'Please provide valid phone number! E.g. +442012345678', {
      message: 'Please provide valid url!',
      excludeEmptyString: !required,
    });

  return {
    [key]: required ? schema.required('Required field') : schema.nullable(),
  };
};

/**
 * @param {string} key Schema attribute.
 * @param {Object} [options] Validation options
 * @param {boolean} [options.required] Is the field required.
 * @returns {Object}
 */
export const urlSchema = (key, { required = false } = {}) => {
  const schema = Yup.string()
    .max(Env.MAX_TEXT_FIELD_LENGTH, 'Cannot be longer than ${max} characters.')
    .matches(Env.URL_PATTERN, {
      message: 'Please provide valid url!',
      excludeEmptyString: !required,
    });

  return {
    [key]: required ? schema.required('Required field') : schema.nullable(),
  };
};
