import Users from '../components/system/users/Users';
// import Contributors from '../components/system/contributors/Contributors';
import Settings from '../components/system/settings/Settings';
import { Roles } from 'modules/shared';

const systemRoutes = [
  // {
  //   path: '/app/admin/system/contributors',
  //   name: 'Contributors',
  //   component: Contributors,
  // },
  {
    path: '/app/admin/system/settings',
    name: 'Settings',
    component: Settings,
    role: [Roles.AnalystAdmin],
  },
  {
    path: '/app/admin/system/users',
    name: 'Users',
    component: Users,
  },
  { redirect: true, from: '/app/admin/system', to: '/app/admin/system/users', key: 'redirect' },
];

export default systemRoutes;
