import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Select, Delete, FieldGroup } from 'modules/admin/shared';
import { QuestionTypes, QuestionTypesLabel } from 'common';

class Question extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    deleteRow: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    this.props.onChange({ index: this.props.index, event: e });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { row, deleteRow, errors, index } = this.props;

    return (
      <Row className="d-flex w-100">
        <Col className="d-flex align-center" xs={3}>
          <FieldGroup
            name="question"
            className="w-100"
            placeholderId="ADMIN.SURVEYS.QUESTION"
            error={errors[`questions[${index}].question`]}
            value={row.question}
            required
            noMargin
            onChange={this.onChange}
          />
        </Col>
        <Col className="d-flex align-center select" xs={2}>
          <Select
            name="type"
            className="w-100"
            value={row.type}
            onChange={this.onChange}
            clearable={false}
            options={Object.values(QuestionTypes).map((id) => ({
              value: id,
              label: QuestionTypesLabel[id].label,
            }))}
          />
        </Col>
        {row.type === QuestionTypes.radioButtons ? (
          <Fragment>
            <Col className="d-flex align-center" xs={1}>
              <FieldGroup
                name="min_result"
                placeholderId="ADMIN.SURVEYS.MIN_RESULT"
                error={errors[`questions[${index}].min_result`]}
                value={row.min_result}
                required
                noMargin
                onChange={this.onChange}
              />
            </Col>
            <Col className="d-flex align-center" xs={1}>
              <FieldGroup
                name="max_result"
                placeholderId="ADMIN.SURVEYS.MAX_RESULT"
                error={errors[`questions[${index}].max_result`]}
                value={row.max_result}
                required
                noMargin
                onChange={this.onChange}
              />
            </Col>
            <Col className="d-flex align-center" xs={2}>
              <FieldGroup
                name="min_label"
                placeholderId="ADMIN.SURVEYS.MIN_LABEL"
                error={errors[`questions[${index}].min_label`]}
                value={row.min_label}
                required
                noMargin
                onChange={this.onChange}
              />
            </Col>
            <Col className="d-flex align-center" xs={2}>
              <FieldGroup
                name="max_label"
                placeholderId="ADMIN.SURVEYS.MAX_LABEL"
                error={errors[`questions[${index}].max_label`]}
                value={row.max_label}
                required
                noMargin
                onChange={this.onChange}
              />
            </Col>
          </Fragment>
        ) : null}
        <Col xs={1} className="d-flex align-center">
          <Delete onSubmit={() => deleteRow(index)} />
        </Col>
      </Row>
    );
  }
}

export default Question;
