import * as PostsService from 'modules/shared/services/posts';
import { Status } from 'modules/shared/util/http';
import fileDownload from 'js-file-download';
import { formatDate } from 'common';

export const GET_POSTS = 'GET_POSTS';
export const DELETE_POST = 'DELETE_POST';
export const BOOKMARK_POST = 'BOOKMARK_POST';
export const GET_POST = 'GET_POST';
export const SAVE_POST = 'SAVE_POST';
export const MODIFY_POST = 'MODIFY_POST';
export const SAVE_POST_FEATURED_IMAGE = 'SAVE_POST_FEATURED_IMAGE';
export const SAVE_POST_CONTENT_IMAGE = 'SAVE_POST_CONTENT_IMAGE';
export const ANSWER_QUESTION = 'ANSWER_QUESTION';

/**
 * @description Gets one page from the post.
 * @param {Object} options Search options.
 * @returns {Function}
 */
export const getPosts = (options) => async () => {
  return await PostsService.getPosts(options);
};

/**
 * @description Gets one page from the post.
 * @param {Object} options Paginating options.
 * @returns {Function}
 */
export const getPostsPage = (options) => async () => {
  return await PostsService.getPostsPage(options);
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const deletePost = (id) => async (dispatch) => {
  const post = await PostsService.deletePost(id);

  dispatch({
    type: DELETE_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const bookmarkPost = (id) => async (dispatch) => {
  const bookmark = await PostsService.bookmarkpost(id);

  dispatch({
    type: BOOKMARK_POST,
    payload: bookmark,
  });

  return bookmark;
};

/**
 * @description Gets the post for the ID:
 * @param {number} id post ID.
 * @returns {Function}
 */
export const getPost = (id) => async (dispatch) => {
  try {
    const post = await PostsService.getPost(id);

    dispatch({
      type: GET_POSTS,
      payload: post,
    });

    return post;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};

export const getAdminPost = (id) => async (dispatch) => {
  try {
    const post = await PostsService.getAdminPost(id);

    dispatch({
      type: GET_POSTS,
      payload: post,
    });

    return post;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};

/**
 * @param {Object} data post data.
 * @returns {Function}
 */
export const savePost = (data) => async (dispatch) => {
  const post = await PostsService.savePost(data);

  dispatch({
    type: SAVE_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const modifyPost = (id, data) => async (dispatch) => {
  const post = await PostsService.modifyPost(id, data);

  dispatch({
    type: MODIFY_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveFeaturedImage = (id, data) => async (dispatch) => {
  const post = await PostsService.saveFeaturedImage(id, data);

  dispatch({
    type: SAVE_POST_FEATURED_IMAGE,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveContentImage = (id, data) => async (dispatch) => {
  const post = await PostsService.saveContentImage(id, data);

  dispatch({
    type: SAVE_POST_FEATURED_IMAGE,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} mediaId download file id.
 * @returns {Function}
 */
export const deleteDownloadFile = (id, mediaId) => async () => {
  return await PostsService.deleteDownloadFile(id, mediaId);
};

/**
 * @param {number} id Post ID
 * @returns {function(): *}
 */
export const getOtherVideos = (id) => async () => {
  return await PostsService.getOtherVideos(id);
};

/**
 * @param {number} id Post ID
 * @returns {function(): *}
 */
export const getMoreIdeas = (id) => async () => {
  return await PostsService.getMoreIdeas(id);
};

/**
 * @param {Object} options Search options.
 * @returns {function(): *}
 */
export const exportPosts = (options) => async () => {
  const data = await PostsService.exportPosts(options);
  fileDownload(data, `posts-${formatDate(new Date(), { timeFormat: true })}.csv`);

  return data;
};

/**
 * @param {Object} data answer with question id, user vote id
 * @description Answer question.
 * @returns {Function}
 */
export const answerQuestion = (data) => async () => {
  return PostsService.answerQuestion(data);
};

/**
 * @param {Object} data sharepoint url
 * @description Sync with sharepoint.
 * @returns {Function}
 */
export const syncSharepoint = (data) => async () => {
  return PostsService.syncSharepoint(data);
};

/**
 * @param {Object} data sharepoint url
 * @description Check sharepoint sync.
 * @returns {Function}
 */
export const checkSharepointSync = (data) => async () => {
  return PostsService.checkSharepointSync(data);
};
