import { combineReducers } from 'redux';
import profile from './profile';
import loader from './loader';
import employees from './employees';
import users from './users';
import userSettings from './userSettings';
import userPreferences from './userPreferences';
import roles from './roles';
import systemSettings from './system-settings';

const rootReducer = combineReducers({
  profile,
  userSettings,
  userPreferences,
  loader,
  employees,
  users,
  roles,
  systemSettings,
});

export default rootReducer;
