import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CompaniesCard from './CompaniesCard';
import withStyles from 'isomorphic-style-loader/withStyles';
import { SearchInput } from 'modules/shared';
import styles from './AZCompanies.scss';
import { injectIntl } from 'react-intl';
import Env from 'env';

class AZCompanies extends Component {
  static propTypes = {
    active: PropTypes.array.isRequired,
    onFilter: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
    basePath: PropTypes.string.isRequired,
    subPath: PropTypes.string,
    searchValue: PropTypes.string.isRequired,
    bookmark: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    isHighlighted: PropTypes.func,
    bookmarks: PropTypes.array.isRequired,
    rounded: PropTypes.bool,
    intl: PropTypes.object,
    setEditItem: PropTypes.func.isRequired,
  };

  /**
   * @description set edit link
   */
  componentDidMount() {
    this.props.setEditItem({
      text: this.props.intl.formatMessage({ id: 'ANALYST.EDIT_COMPANIES' }),
      link: `/app/admin/website-structure/companies`,
    });
  }

  isBookmarked = (company) => {
    return company.bookmark_id !== Env.NO_COMPANY_BOOKMARK;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="a-z-companies">
        <div className="search">
          <SearchInput onChange={this.props.onSearch} value={this.props.searchValue} />
        </div>
        <div className="filter">
          <span
            className={classNames('letter', { active: !this.props.active.length })}
            onClick={() => this.props.onFilter(null)}>
            <FormattedMessage id="MAIN.ALL" />
          </span>
          {this.props.companies.map(({ key }) => (
            <span
              onClick={() => this.props.onFilter(key)}
              key={key}
              className={classNames('letter', { active: this.props.active.includes(key) })}>
              {key}
            </span>
          ))}
        </div>
        <div>
          {this.props.companies
            .filter(({ items }) => items.length)
            .map(({ key, items }) => (
              <CompaniesCard
                key={key}
                companies={items}
                title={key}
                basePath={this.props.basePath}
                subPath={this.props.subPath}
                isBookmarked={this.isBookmarked}
                bookmark={this.props.bookmark}
                bookmarks={this.props.bookmarks}
                rounded={this.props.rounded}
                isHighlighted={this.props.isHighlighted}
              />
            ))}
        </div>
        {!this.props.companies.filter(({ items }) => items.length).length ? (
          <div>
            <FormattedMessage id={'MAIN.SEARCH_COMPANY_EMPTY'} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(AZCompanies));
