import { UserAnalytics, ArticleAnalytics, SurveyAnalytics } from '../components/analytics';

const analyticsRoutes = [
  {
    path: '/app/admin/analytics/article-analytics',
    name: 'Article Analytics',
    component: ArticleAnalytics,
  },
  {
    path: '/app/admin/analytics/survey-analytics',
    name: 'Survey Analytics',
    component: SurveyAnalytics,
  },
  {
    path: '/app/admin/analytics/user-analytics',
    name: 'User Analytics',
    component: UserAnalytics,
  },
  {
    redirect: true,
    from: '/app/admin/analytics',
    to: '/app/admin/analytics/user-analytics',
    key: 'redirect',
  },
];

export default analyticsRoutes;
