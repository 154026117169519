import Analytics from '../components/analytics/analytics/Analytics';
import System from '../components/system/System';
import Content from '../components/content/Content';
import WebsiteStructure from '../components/website-structure/WebsiteStructure';
import Metadata from '../components/metadata/Metadata';

import contentRoutes from 'modules/admin/main/routes/content';
import websiteStructureRoutes from 'modules/admin/main/routes/website-structure';
import metadataRoutes from 'modules/admin/main/routes/metadata';
import systemRoutes from 'modules/admin/main/routes/system.jsx';
import analyticsRoutes from 'modules/admin/main/routes/analytics.jsx';

const homeRoutes = [
  {
    path: '/app/admin/analytics',
    name: 'Analytics',
    component: Analytics,
    key: 'analytics-',
    subRoutes: analyticsRoutes,
  },
  {
    path: '/app/admin/system',
    name: 'System',
    component: System,
    key: 'system-',
    subRoutes: systemRoutes,
  },
  {
    path: '/app/admin/content',
    name: 'Content Pages',
    component: Content,
    key: 'content-',
    subRoutes: contentRoutes,
  },
  {
    path: '/app/admin/website-structure',
    name: 'Website Structure',
    component: WebsiteStructure,
    key: 'website-structure-',
    subRoutes: websiteStructureRoutes,
  },
  {
    path: '/app/admin/metadata',
    name: 'Metadata',
    component: Metadata,
    key: 'metadata-',
    subRoutes: metadataRoutes,
  },
  { redirect: true, from: '/app/admin', to: '/app/admin/content/posts', key: 'redirect' },
];

export default homeRoutes;
