import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

/**
 * @param {string} order filter by order
 * @param {string} base resource
 * @description Gets all sectors.
 * @returns {Promise<*>}
 */
export const getSectors = async ({ base = 'sectors', ...params } = {}) => {
  const resource = `${base}${getPaginationQueryParams(params)}`;
  return await request({
    resource,
    method: Methods.GET,
  });
};

/**
 * @param {number} id Sector ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const getSector = async (id, base = 'sectors') => {
  return await request({
    resource: `${base}/${id}`,
  });
};

/**
 * @param {Object} data sector object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const saveSector = async (data, base = 'sectors') => {
  return await request({
    resource: `${base}`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Sector ID
 * @param {Object} data Sector object
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const modifySector = async (id, data, base = 'sectors') => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Sector ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const deleteSector = async (id, base = 'sectors') => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {string} message message
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const sendSubscription = (message, base = 'sectors') => {
  return request({
    resource: `${base}/contact`,
    method: Methods.POST,
    data: message,
  });
};

/**
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const getIndustrySectors = async (base = 'sector_industries') => {
  return await request({
    resource: `${base}`,
  });
};

/**
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const getEntitlements = async (base = 'entitlements') => {
  return await request({
    resource: `${base}`,
  });
};
