import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import SurveysList from 'modules/admin/main/components/website-structure/surveys/SurveysList';
import SurveyAnalyticsDetail from './SurveyAnalyticsDetail';
import { match } from 'common';

class UserAnalytics extends Component {
  static propTypes = {
    match,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Switch>
        <Route
          sensitive
          path={`${this.props.match.path}/:id/survey`}
          render={(props) => <SurveyAnalyticsDetail {...props} />}
        />
        <Route
          sensitive
          path={`${this.props.match.path}/`}
          render={(props) => (
            <SurveysList
              {...props}
              basePath={this.props.match.path}
              toLinkName={'survey'}
              disabled
            />
          )}
        />
      </Switch>
    );
  }
}

export default UserAnalytics;
