import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { match, history, blogSectionOptions, getDSTDate, setDSTDateString } from 'common';
import { isValidSchema, textSchema } from 'common/shemaValidator';
import {
  EditorTitle,
  Button,
  FieldGroup,
  Select,
  TextEditor,
  Downloads,
  FileUpload,
} from 'modules/admin/shared';
import { DateTimePicker } from 'modules/shared';
import Env from 'env';
import moment from 'moment/moment';
import { arrayMove } from 'react-sortable-hoc';

class BlogPostsEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getBlogPost: PropTypes.func.isRequired,
    saveBlogPost: PropTypes.func.isRequired,
    modifyBlogPost: PropTypes.func.isRequired,
    saveFeaturedImage: PropTypes.func.isRequired,
    saveContentImage: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    viewLink: PropTypes.string,
    getRegions: PropTypes.func,
    getBlogTags: PropTypes.func,
    regions: PropTypes.array,
    blogtags: PropTypes.array,
    basePath: PropTypes.string,
    createTitle: PropTypes.node.isRequired,
    editTitle: PropTypes.node.isRequired,
    listViewPath: PropTypes.string,
    match: match.isRequired,
    history: history.isRequired,
    hiddenFields: PropTypes.array,
  };

  edit = !!this.props.match.params.id;
  state = {
    blogPost: {
      title: '',
      status: 'Draft',
      summary: '',
      content: '',
      featured_image_path: '',
      meta_title: '',
      meta_description: '',
      keywords: '',
      tags: [],
      regions: [],
      featured_image: [
        {
          ...BlogPostsEditor.getFeaturedImageObject(),
        },
      ],
      downloads: [],
      published_at: null,
    },
    errors: {},
    isValid: false,
    loading: true,
    isReady: true,
  };

  schema = {
    ...textSchema('title', { required: true }),
    ...textSchema('summary', { required: true }),
    ...textSchema('content', { required: true, max: Env.MAX_TEXT_EDITOR_LENGTH }),
    ...textSchema('section', { required: !this.props.hiddenFields.includes('section') }),
    ...textSchema('keywords'),
    ...textSchema('meta_title'),
    ...textSchema('meta_description'),
  };

  /**
   * @returns {Object} featured_image object
   * */
  static getFeaturedImageObject() {
    return {
      custom_properties: {
        fileTitle: '',
        fileAlt: '',
      },
    };
  }

  /**
   * @returns {Object} return new file object.
   */
  static getFile() {
    return { custom_properties: { title: '', description: '' }, data: {}, file_name: '' };
  }

  getMultiSelectData = (data, nameKey) => {
    return data ? data.map((item) => ({ label: item[nameKey], value: item.id })) : [];
  };

  getSingleSelectData = (data, nameKey) => {
    return data && data[0] ? { label: data[0][nameKey], value: data[0].id } : {};
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();
    if (this.edit) {
      try {
        const [blogPost] = await Promise.all([
          this.props.getBlogPost(this.props.match.params.id),
          ...this.getApiLists(),
        ]);
        this.setState({
          blogPost: {
            ...blogPost,
            tags: this.getMultiSelectData(blogPost.tags, 'name'),
            regions: this.getMultiSelectData(blogPost.regions, 'title'),
            featured_image:
              blogPost.featured_image && blogPost.featured_image.length > 0
                ? blogPost.featured_image
                : [{ ...BlogPostsEditor.getFeaturedImageObject() }],
            published_at: getDSTDate(blogPost.published_at),
          },
          alreadySetPublished: !!blogPost.published_at,
          loading: false,
          isValid: true,
        });
      } catch (e) {
        this.setState({ loading: false });
      }
    } else {
      await Promise.all([...this.getApiLists()]);
      this.setState({
        loading: false,
      });
    }
    this.props.hideLoader();
  }

  /**
   * @returns {array} callAble api list
   * */
  getApiLists() {
    const listApi = ['getRegions', 'getBlogTags'];

    const callAbleApiList = [];
    for (const api of listApi) {
      if (this.props[api]) {
        callAbleApiList.push(this.props[api]());
      }
    }

    return callAbleApiList;
  }

  /**
   * @returns {boolean} editor is valid.
   */
  isValidForm = async () => {
    const schema = Yup.object().shape({ ...this.schema });

    return await schema.isValid(this.state.blogPost);
  };

  /**
   * @param {Event} e Event object
   * @returns {Object} Fake Event object
   */
  flattenSelectEvent = (e) => {
    return {
      ...e,
      target: { ...e.target, value: e.target.value ? e.target.value.value : '' },
    };
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;
    const checked = e.target.checked;

    this.setState(
      {
        blogPost: {
          ...this.state.blogPost,
          [name]: type === 'checkbox' ? checked : value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm();
        const { error } = await isValidSchema(
          { [name]: this.schema[name] },
          {
            [name]: value,
          }
        );

        errors[name] = error;

        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  /**
   * @param {Event} e Event data.
   */
  onChangeImageDetails = async (e) => {
    const { name, value } = e.target;

    const featured_image = JSON.parse(JSON.stringify(this.state.blogPost.featured_image));
    featured_image[0].custom_properties[name] = value;

    this.setState({
      blogPost: { ...this.state.blogPost, featured_image },
    });
  };

  /**
   * @param {Event} e Event data.
   */
  onChangeMultiSelect = async (e) => {
    this.setState({
      blogPost: {
        ...this.state.blogPost,
        [e.target.name]: e.target.value,
      },
    });
  };

  getIds = (data) => {
    return data ? data.map((item) => item.value) : [];
  };

  /**
   *
   * @param {Object} e event
   * @param {boolean} publish immediately
   *
   * @description save form
   */
  save = async (e, publish) => {
    const blogPost = { ...this.state.blogPost };

    for (const hiddenField of this.props.hiddenFields) {
      delete blogPost[hiddenField];
    }

    const blogPostToSave = {
      ...blogPost,
      status: publish ? 'active' : 'Draft',
    };

    this.setState({
      blogPost: {
        ...blogPostToSave,
        published_at: blogPostToSave.published_at ? moment(blogPostToSave.published_at) : null,
      },
    });
    delete blogPostToSave.regions;
    delete blogPostToSave.blogtags;
    blogPostToSave.regions = this.getIds(this.state.blogPost.regions);
    blogPostToSave.tags = this.getIds(this.state.blogPost.tags);
    blogPostToSave.published_at = blogPost.published_at
      ? setDSTDateString(blogPost.published_at)
      : null;

    if (blogPostToSave.featured_image[0] && !blogPostToSave.featured_image[0].data) {
      blogPostToSave.featured_image = null;
    }

    try {
      if (typeof blogPostToSave.id !== 'undefined') {
        const { published_at } = await this.props.modifyBlogPost(blogPostToSave.id, blogPostToSave);
        this.setState({
          blogPost: {
            ...this.state.blogPost,
            published_at: getDSTDate(published_at),
          },
        });
      } else {
        const { id, published_at } = await this.props.saveBlogPost(blogPostToSave);
        this.setState({
          blogPost: {
            ...this.state.blogPost,
            id,
            published_at: getDSTDate(published_at),
          },
        });
        this.props.history.push(`/app/admin/${this.props.listViewPath}/${id}/edit`);
      }
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ loading: false });
    }
  };

  publish = async () => {
    try {
      this.setState({ publishing: true });
      await this.props.modifyBlogPost(this.state.blogPost.id, { status: 'active' });
      toast.success(<FormattedMessage id="MAIN.PUBLISH_SUCCESS" />);
    } finally {
      this.setState({ publishing: false });
    }
  };

  onDropFile = async (files, index, type) => {
    if (!!index || (index === 0 && type === 'downloads')) {
      // downloads
      const blogPost = {
        ...this.state.blogPost,
        downloads: [...this.state.blogPost.downloads],
      };

      if (files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          blogPost.downloads[index].data = reader.result;
          blogPost.downloads[index].file_name = files[0].name;
          blogPost.downloads[index].mime_type = files[0].type;
          delete blogPost.downloads[index].id;
          this.setState({ blogPost });
        };
      }
    } else {
      //featured_image
      const featured_image = this.state.blogPost.featured_image.map((data) => data);
      featured_image[0] = { ...featured_image[0] };

      if (files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          featured_image[0].data = reader.result;
          featured_image[0].file_name = files[0].name;
          featured_image[0].mime_type = files[0].type;
          delete featured_image[0].id;
          this.setState({
            blogPost: {
              ...this.state.blogPost,
              featured_image,
              featured_image_path: files.length > 0 ? files[0] : null,
            },
          });
        };
      }
    }
  };

  deleteFile = async () => {
    this.setState({
      blogPost: { ...this.state.blogPost, featured_image_path: null },
    });
  };

  saveFeatureImage = async (id) => {
    const formData = new FormData();
    formData.append('featured_image', this.state.blogPost.featured_image_path);

    await this.props.saveFeaturedImage(id, formData);
  };

  setReadyState = (isReady) => {
    this.setState({ isReady });
  };

  isPublishDisabled = () => {
    return (
      !this.state.isValid ||
      !this.state.isReady ||
      !this.state.blogPost.id ||
      this.state.blogPost.status === 'active'
    );
  };

  /**
   * @param {Date} date published_at date
   * @description set published_at property
   * */
  onChangePublishedDate = (date) => {
    this.setState({
      blogPost: {
        ...this.state.blogPost,
        published_at: date,
      },
    });
  };

  addFile = () => {
    const downloads = this.state.blogPost.downloads ? this.state.blogPost.downloads : [];
    const blogPost = {
      ...this.state.blogPost,
      downloads: [...downloads, BlogPostsEditor.getFile()],
    };

    this.setState({ blogPost });
  };

  onChangeFile = (e, index) => {
    const blogPost = {
      ...this.state.blogPost,
      downloads: [...this.state.blogPost.downloads],
    };

    blogPost.downloads[index].custom_properties[e.target.name] = e.target.value;

    this.setState({ blogPost });
  };

  removeFile = async (index) => {
    const downloads = this.state.blogPost.downloads.map((data) => data);
    const deletedFile = downloads.splice(index, 1);

    if (this.edit && deletedFile[0].id) {
      await this.props.deleteFile({
        media_id: deletedFile[0].id,
      });
    }

    this.setState({ blogPost: { ...this.state.blogPost, downloads } });
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    const downloads = arrayMove(this.state.blogPost.downloads, oldIndex, newIndex);
    for (let i = 0; i < downloads.length; i++) {
      downloads[i].order_column = i;
    }
    this.setState({
      blogPost: {
        ...this.state.blogPost,
        downloads,
      },
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        {!this.state.loading ? (
          <form noValidate>
            <EditorTitle
              title={
                typeof this.state.blogPost.id !== 'undefined'
                  ? this.props.editTitle
                  : this.props.createTitle
              }
              viewLink={
                typeof this.state.blogPost.id !== 'undefined'
                  ? `${this.props.viewLink}/${this.state.blogPost.id}`
                  : null
              }>
              <div className="d-inline-flex">
                <Button
                  loading={this.state.saving}
                  type="button"
                  onClick={async (e) => {
                    this.setState({ saving: true });
                    try {
                      await this.save(e);
                    } finally {
                      this.setState({ saving: false });
                    }
                  }}
                  disabled={!this.state.isValid || !this.state.isReady}>
                  <FormattedMessage id="ADMIN.SAVE" />
                </Button>

                <Button
                  className="ml-2"
                  loading={this.state.saveAndPublishing}
                  type="button"
                  onClick={async (e) => {
                    this.setState({ saveAndPublishing: true });
                    await this.save(e, true);
                    this.setState({ saveAndPublishing: false });
                  }}
                  disabled={!this.state.isValid || !this.state.isReady}>
                  <FormattedMessage id="ADMIN.SAVE_AND_PUBLISH" />
                </Button>

                <Button
                  className="ml-2"
                  loading={this.state.publishing}
                  type="button"
                  onClick={this.publish}
                  disabled={this.isPublishDisabled()}>
                  <FormattedMessage id="ADMIN.PUBLISH" />
                </Button>
              </div>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12} sm={6}>
                  <Col xs={12}>
                    <FieldGroup
                      name="title"
                      placeholderId="ADMIN.TITLE"
                      label={<FormattedMessage id="ADMIN.TITLE" />}
                      error={this.state.errors.title ? this.state.errors.title.message : null}
                      value={this.state.blogPost.title}
                      required
                      onChange={this.onChange}
                    />
                  </Col>

                  <Col xs={12}>
                    <FieldGroup
                      componentClass="textarea"
                      placeholderId="ADMIN.SUMMARY"
                      label={<FormattedMessage id="ADMIN.SUMMARY" />}
                      name="summary"
                      error={this.state.errors.summary ? this.state.errors.summary.message : null}
                      value={this.state.blogPost.summary}
                      onChange={this.onChange}
                      required
                    />
                  </Col>

                  {/*blogtags*/}
                  {!this.props.hiddenFields.includes('blogtags') ? (
                    <Col xs={12}>
                      <Select
                        name="tags"
                        label={<FormattedMessage id="ADMIN.POSTS.TAGS" />}
                        multi
                        value={this.state.blogPost.tags}
                        bottomMargin
                        onChange={(e) => this.onChangeMultiSelect(e)}
                        options={this.props.blogtags.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))}
                      />
                    </Col>
                  ) : null}

                  {/*regions*/}
                  {!this.props.hiddenFields.includes('regions') ? (
                    <Col xs={12}>
                      <Select
                        name="regions"
                        label={<FormattedMessage id="ADMIN.POSTS.REGIONS" />}
                        multi
                        value={this.state.blogPost.regions}
                        bottomMargin
                        onChange={(e) => this.onChangeMultiSelect(e)}
                        options={
                          this.props.regions &&
                          this.props.regions.map(({ title, id }) => ({
                            label: title,
                            value: id,
                          }))
                        }
                      />
                    </Col>
                  ) : null}

                  {/*section*/}
                  {!this.props.hiddenFields.includes('section') ? (
                    <Col xs={12}>
                      <Select
                        name="section"
                        required
                        label={<FormattedMessage id="ADMIN.BLOG_POSTS.SECTION" />}
                        value={this.state.blogPost.section}
                        bottomMargin
                        onChange={(e) => this.onChange(this.flattenSelectEvent(e))}
                        options={
                          blogSectionOptions &&
                          blogSectionOptions.map(({ name, id }) => ({
                            label: <FormattedMessage id={name} />,
                            value: id,
                          }))
                        }
                      />
                    </Col>
                  ) : null}
                </Col>

                <Col xs={12} sm={6} className="image-header">
                  <FormGroup className="image-preview-container">
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.IMAGE" />
                    </ControlLabel>
                    <FileUpload
                      fileTitle={this.state.blogPost.featured_image[0].custom_properties.fileTitle}
                      fileAlt={this.state.blogPost.featured_image[0].custom_properties.fileAlt}
                      file={this.state.blogPost.featured_image_path}
                      fileId={this.state.blogPost.featured_image[0].id}
                      onChange={this.onChangeImageDetails}
                      deleteFile={this.state.blogPost.featured_image[0].id ? this.deleteFile : null}
                      onDropFile={(files) => this.onDropFile(files)}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={6}>
                  <ControlLabel>
                    <FormattedMessage id="ADMIN.POSTS.PUBLISHED_AT" />
                  </ControlLabel>
                  <DateTimePicker
                    selected={this.state.blogPost.published_at}
                    onChange={this.onChangePublishedDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={Env.PUBLISH_DATE_TIME_INTERVAL}
                    dateFormat="LLL"
                    timeCaption="time"
                  />
                </Col>

                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="keywords"
                    placeholderId="ADMIN.KEYWORDS"
                    label={<FormattedMessage id="ADMIN.KEYWORDS" />}
                    error={this.state.errors.keywords ? this.state.errors.keywords.message : null}
                    value={this.state.blogPost.keywords}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="meta_title"
                    placeholderId="ADMIN.META_TITLE"
                    label={<FormattedMessage id="ADMIN.META_TITLE" />}
                    error={
                      this.state.errors.meta_title ? this.state.errors.meta_title.message : null
                    }
                    value={this.state.blogPost.meta_title}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="meta_description"
                    placeholderId="ADMIN.META_DESCRIPTION"
                    label={<FormattedMessage id="ADMIN.META_DESCRIPTION" />}
                    error={
                      this.state.errors.meta_description
                        ? this.state.errors.meta_description.message
                        : null
                    }
                    value={this.state.blogPost.meta_description}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs={12}>
                  <ControlLabel>
                    <FormattedMessage id="ADMIN.POSTS.CONTENT" />*
                  </ControlLabel>
                  <TextEditor
                    name="content"
                    className="post-html-content"
                    text={this.state.blogPost.content}
                    setReadyState={this.setReadyState}
                    handleChange={this.onChange}
                  />
                </Col>

                <Col xs={12}>
                  <ControlLabel>
                    <FormattedMessage id="ADMIN.POSTS.DOWNLOADS" />
                  </ControlLabel>
                  <Downloads
                    name="downloads"
                    onChange={this.onChangeFile}
                    removeFile={this.removeFile}
                    addFile={this.addFile}
                    onDropImage={this.onDropFile}
                    files={this.state.blogPost.downloads}
                    onDragEnd={this.onDragEnd}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

export default BlogPostsEditor;
