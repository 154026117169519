import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useDispatch, useSelector } from 'react-redux';

import Env from 'env';
import { useQuery } from 'modules/shared/util/useQuery';
import {
  getLongScreen,
  getLongScreenFactors,
  voteOnFactors,
} from 'modules/analyst/actions/long-screen';
import {
  AnswerButton,
  AnswerButtonType,
  Html,
  Spinner,
  StarDescription,
  Roles,
  CanAccess,
  StarIcon,
  SvgIcon,
} from 'modules/shared';
import {
  LongScreenFactorModel,
  LongScreenFactorQuestionModel,
  LongScreenModel,
} from 'modules/analyst/models';
import { RelatedPosts } from 'modules/shared/components';
import { getPostsPage } from 'modules/analyst/actions/posts';
import { bookmarkPost } from 'modules/analyst/actions/posts';
import { deleteAnalystBookmark as deleteBookmark } from 'modules/shared/actions/bookmarks';

import LongScreenBar from './LongScreenBar';

import styles from './LongScreen.scss';

const MAX_VOTES = 3;
const STAR_VOTES = 10;

interface LongScreenProps {
  basePath?: string;
  setEditItem?: (options: { text: string; link: string }) => void;
}

function LongScreen({ setEditItem, basePath }: LongScreenProps) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isVoting, setIsVoting] = useState(false);
  const systemSettings = useSelector((state: any) => state.app.systemSettings.all);
  const longScreen = useQuery<LongScreenModel>(() => dispatch(getLongScreen()));
  const factors = useQuery<LongScreenFactorModel>(() => dispatch(getLongScreenFactors()));
  const [clickedFactors, setClickedFactors] = useState<ID[]>([]);
  const isLoading = useMemo(() => Boolean(longScreen.isLoading || factors.isLoading), [
    longScreen,
    factors,
  ]);
  const hasData = useMemo(() => Boolean(longScreen.data && factors.data), [longScreen, factors]);
  const mostPercentage = useMemo(() => {
    const percentages = factors.data?.questions.map((question) => question.percentage);

    if (!percentages) {
      return 0;
    }

    return Math.max(...percentages);
  }, [factors]);
  const showContent = hasData && !isLoading;
  const canVote = !factors.data?.votedInWindow && longScreen.data?.has_active_voting_window;
  const maxVotes = systemSettings.long_screen_vote_per_user
    ? Number.parseInt(systemSettings.long_screen_vote_per_user)
    : MAX_VOTES;

  const votingButtonDisabled =
    (canVote && clickedFactors.length < 1) || !longScreen.data?.has_active_voting_window;

  useEffect(() => {
    if (!setEditItem) {
      return;
    }

    setEditItem({
      text: intl.formatMessage({ id: 'ADMIN.LONG_SCREEN.EDIT_LONG_SCREEN' }),
      link: `/app/admin/content/factor-panel`,
    });
  }, [intl, setEditItem]);

  const onFactorClick = useCallback(
    (factor: LongScreenFactorQuestionModel) => {
      if (!canVote) {
        return;
      }

      if (clickedFactors.includes(factor.id)) {
        setClickedFactors(clickedFactors.filter((id) => id !== factor.id));
      } else if (clickedFactors.length < maxVotes) {
        setClickedFactors([...new Set([...clickedFactors, factor.id])]);
      } else {
        clickedFactors[clickedFactors.length - 1] = factor.id;
        setClickedFactors([...new Set([...clickedFactors])]);
      }
    },
    [clickedFactors, setClickedFactors, canVote, maxVotes]
  );

  const onVote = useCallback(async () => {
    if (!canVote || isVoting) {
      return;
    }

    if (clickedFactors.length < 1) {
      return;
    }

    try {
      setIsVoting(true);
      await dispatch(voteOnFactors(clickedFactors));
      await factors.reload(false);
    } finally {
      setIsVoting(false);
    }
  }, [canVote, clickedFactors, setIsVoting, dispatch, isVoting, factors]);

  const onBookmark = useCallback(
    async (id: number) => {
      return dispatch(bookmarkPost(id));
    },
    [dispatch]
  );

  const onDeleteBookmark = useCallback(
    async (id: number) => {
      return dispatch(deleteBookmark(id));
    },
    [dispatch]
  );

  const fetchPosts = useCallback(
    async (params: Record<string, any>) => {
      return dispatch(
        getPostsPage({
          ...params,
          onlylongscreen: true,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <FormattedMessage id="ADMIN.LONG_SCREEN.TITLE">
        {(title) => (
          <Helmet defer={false}>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      {showContent ? (
        <Grid fluid className="long-screen">
          <div className="d-flex flex-column">
            <Row className="white-background d-flex">
              <div className="d-flex flex-wrap">
                <Col xs={12} md={7} className="long-screen-detail-section">
                  <div className="long-screen-title">
                    <img
                      alt="Factor Panel Logo"
                      className="svg"
                      src={require('assets/img/factor-panel-image.png')}
                    />
                  </div>
                  <div>
                    <p>{longScreen.data.description}</p>
                    <StarDescription star_description={longScreen.data.star_description} />
                    <CanAccess allowedRoles={[Roles.AnalystAdmin, Roles.AnalystEditor]}>
                      <h4>{systemSettings.voting_button_note}</h4>
                    </CanAccess>
                  </div>
                </Col>
                <Col xs={12} md={5} className="long-screen-info-section">
                  <Col xs={12}>
                    {longScreen.data.video_url ? (
                      <Html
                        className="video-container"
                        content={longScreen.data.vimeo_data.embed}
                      />
                    ) : null}
                  </Col>
                </Col>
                <Col xs={12}>
                  <hr className="long-screen-divider" />

                  <div className="long-screen-factor-question">
                    <h4>{longScreen.data.vote_title}</h4>
                    <CanAccess
                      allowedRoles={[Roles.AnalystSubscriber]}
                      unauthenticatedComponent={
                        <div className="long-screen-voting-window-info disabled">
                          {!longScreen.data.has_active_voting_window ? (
                            <FormattedMessage id="ANALYST.LONG_SCREEN.VOTING_WINDOW_CLOSED" />
                          ) : null}
                          {longScreen.data.has_active_voting_window ? (
                            <FormattedMessage id="ANALYST.LONG_SCREEN.SUBMIT_VOTES" />
                          ) : null}
                        </div>
                      }>
                      <div
                        className={classNames('long-screen-voting-window-info', {
                          disabled: votingButtonDisabled,
                          clickable: canVote && clickedFactors.length >= 1,
                          'is-voting': isVoting,
                        })}
                        onClick={onVote}>
                        <span className="long-screen-voting-window-info-text">
                          {!longScreen.data.has_active_voting_window &&
                          !factors.data.votedInWindow ? (
                            <FormattedMessage id="ANALYST.LONG_SCREEN.VOTING_WINDOW_CLOSED" />
                          ) : null}
                          {factors.data.votedInWindow ? (
                            <>
                              <SvgIcon
                                className="long-screen-voting-window-info-tick"
                                name="tick"
                                width={null}
                                height={null}
                              />
                              <FormattedMessage id="ANALYST.LONG_SCREEN.VOTING_THANK_YOU" />
                            </>
                          ) : null}
                          {longScreen.data.has_active_voting_window &&
                          !factors.data.votedInWindow ? (
                            <FormattedMessage id="ANALYST.LONG_SCREEN.SUBMIT_VOTES" />
                          ) : null}
                        </span>

                        {isVoting ? (
                          <span className="long-screen-voting-window-info-spinner">
                            <Spinner size="small" />
                          </span>
                        ) : null}
                      </div>
                    </CanAccess>
                  </div>
                </Col>

                <Col xs={12} className="long-screen-factor-container">
                  {factors.data.questions.map((factor) => {
                    return (
                      <div key={factor.id} className="long-screen-factor-row">
                        <span className="long-screen-factor-title-container">
                          <span
                            className="long-screen-factor-title"
                            dangerouslySetInnerHTML={{ __html: factor.factor_name }}
                          />
                          {factors.data?.votedInWindow && factor.voteCount < STAR_VOTES ? (
                            <StarIcon className="long-screen-factor-title-star" />
                          ) : null}
                        </span>

                        <div className="long-screen-answer-container">
                          <AnswerButton
                            disabled={
                              factors.data.votedInWindow ||
                              !longScreen.data.has_active_voting_window
                            }
                            type={AnswerButtonType.LIKE}
                            active={factor.vote || clickedFactors.includes(factor.id)}
                            onClick={() => onFactorClick(factor)}
                          />

                          {factors.data.votedInWindow ? (
                            <LongScreenBar
                              currentValue={factor.percentage}
                              mostValue={mostPercentage}
                              label={factor.percentage}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </div>
            </Row>
            <RelatedPosts
              bookmarkPost={onBookmark}
              deleteBookmark={onDeleteBookmark}
              fetchData={fetchPosts}
              basePath={basePath}
              defaultImage={Env.ANALYST_DEFAULT_IMAGE}
              showTotal
              moreWidth
              hide={isLoading}
            />
          </div>
        </Grid>
      ) : null}
      {!isLoading && !hasData ? (
        <div className="d-flex justify-center align-center model-portfolio-empty-state">
          <FormattedMessage id="ANALYST.LONG_SCREEN.EMPTY_STATE" />
        </div>
      ) : null}
    </>
  );
}

export default withStyles(styles)(LongScreen);
