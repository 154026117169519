import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Content, Button } from 'modules/shared';
// import { MainHeader } from 'modules/main';
import Env from 'env';

import style from './NotFound.scss';

/**
 * @returns {JSX.Element} - Not found component. Uses HttpStatus.
 */
const NotFound = () => {
  return (
    <div className="analyst-home not-found">
      <FormattedMessage id="MAIN.PAGE_NOT_FOUND">
        {(title) => (
          <Helmet defer={false}>
            <title itemProp="name">{title}</title>
            <link rel="canonical" href={`${Env.SITE_URL}404`} itemProp="url" />
          </Helmet>
        )}
      </FormattedMessage>

      {/*<MainHeader />*/}

      <Content>
        <div className="code">404</div>
        <div className="title">
          <FormattedMessage id="MAIN.PAGE_NOT_FOUND" />
        </div>
        <Link className="to-home" to="/app" replace>
          <Button textColor="dark" moduleType="analyst">
            <FormattedMessage id="MAIN.BACK_HOME" />
          </Button>
        </Link>
      </Content>
    </div>
  );
};

export default withStyles(style)(NotFound);
