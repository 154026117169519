import CurrentRecommendations from '../components/website-structure/current-recommendations';
import AnalystCompanies from '../components/website-structure/companies/AnalystCompanies';
import Surveys from '../components/website-structure/surveys/Surveys';

const websiteStructureRoutes = [
  {
    path: '/app/admin/website-structure/current-recommendations',
    name: 'All Ideas',
    component: CurrentRecommendations,
  },
  {
    path: '/app/admin/website-structure/companies',
    name: 'Companies',
    component: AnalystCompanies,
  },
  {
    path: '/app/admin/website-structure/surveys',
    name: 'Surveys',
    component: Surveys,
  },
  {
    redirect: true,
    from: '/app/admin/website-structure',
    to: '/app/admin/website-structure/current-recommendations',
    key: 'redirect',
  },
];

export default websiteStructureRoutes;
