import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProfileDropdown.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Dropdown, MenuItem, CanAccess, AdminRoles } from 'modules/shared';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { match, formatUsername } from 'common';
import Env from 'env';

class ProfileDropdown extends React.Component {
  static propTypes = {
    match,
    profile: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    logout: PropTypes.func.isRequired,
    darkColor: PropTypes.bool,
    profilePath: PropTypes.string,
    editItem: PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      link: PropTypes.string,
    }),
  };

  static defaultProps = {
    menuItems: ['profile', 'logout', 'dashboard', 'guide'],
  };

  getProfilePath = () => {
    return this.props.match.path.slice(0, this.props.match.path.lastIndexOf('/'));
  };

  /**
   * @param {Function} props.menuItems - menuItems array.
   * @param {Function} props.logout - logout from app.
   *
   * @returns {JSX.Element} ProfileDropdown component.
   */
  render() {
    const profilePath = this.props.profilePath || this.getProfilePath();
    return (
      <div className="profile-dropdown-container">
        {this.props.profile.id && (
          <Dropdown
            toggle={
              this.props.darkColor ? (
                <i className="icon-user-outline" />
              ) : (
                <i className="icon-user-outline light" />
              )
            }
            id="profile-dropodown">
            <MenuItem header className="header">
              <div className="name">{formatUsername(this.props.profile)}</div>
              <div className="email">{this.props.profile.email}</div>
            </MenuItem>
            {this.props.menuItems.includes('profile') && (
              <MenuItem href={`${profilePath}/profile`}>
                <FormattedMessage id="MAIN.PROFILE" />
                <i className="icon-user-outline" />
              </MenuItem>
            )}
            {this.props.menuItems.includes('guide') && (
              <MenuItem href={`${profilePath}/walk-through`}>
                <FormattedMessage id="MAIN.GUIDE" />
                <i className="icon-info-outline" />
              </MenuItem>
            )}
            <MenuItem external href={Env.SITE_URL}>
              <FormattedMessage id="MAIN.HOME_PAGE" />
              <i className="icon-home-outline" />
            </MenuItem>
            {this.props.menuItems.includes('dashboard') && (
              <CanAccess allowedRoles={AdminRoles}>
                <MenuItem eventKey="3" href="/app/admin">
                  <FormattedMessage id="MAIN.DASHBOARD" />
                  <i className="icon-dashboard-outline" />
                </MenuItem>
              </CanAccess>
            )}
            {this.props.editItem ? (
              <CanAccess allowedRoles={AdminRoles}>
                <MenuItem href={this.props.editItem.link}>
                  {this.props.editItem.text}
                  <i className="icon-edit-outline" />
                </MenuItem>
              </CanAccess>
            ) : null}
            {this.props.menuItems.includes('logout') && (
              <MenuItem eventKey="2" onClick={this.props.logout}>
                <FormattedMessage id="MAIN.SIGN_OUT" />
                <i className="icon-logout-outline" />
              </MenuItem>
            )}
          </Dropdown>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ProfileDropdown));
