import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Row, Col, FormGroup, InputGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { withFormik } from 'formik';
import { FormattedMessage, FormattedDateParts } from 'react-intl';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { textSchema, urlSchema, numberSchema, arraySchema } from 'common/shemaValidator';
import { arrayMove } from 'react-sortable-hoc';

import {
  EditorTitle,
  Button,
  FieldGroup,
  Select,
  ActionIcon,
  Downloads,
  Prompt,
  Modal,
} from 'modules/admin/shared';
import {
  BuyTypes,
  CompanyStatus,
  match,
  history,
  formatPercent,
  formatUsername,
  Buys,
} from 'common';
import * as SendModelService from 'modules/shared/services/send-model';
import * as CompaniesService from 'modules/shared/services/companies';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export const CompaniesEditorForm = withFormik({
  mapPropsToValues: ({ company }) => {
    return { ...company };
  },
  validationSchema: ({ hiddenFields }) => {
    return Yup.object().shape({
      ...textSchema('title', { required: true }),
      ...textSchema('ticker', { required: true }),
      ...textSchema('author_id', { required: !hiddenFields.includes('author_id') }),
      ...arraySchema('share_owner_analyst_ids'),
      ...textSchema('price_target'),
      ...numberSchema('percent'),
      ...textSchema('buy_type', { required: !hiddenFields.includes('buy_type') }),
      ...textSchema('symbol'),
      ...textSchema('status', { required: true }),
      ...urlSchema('video_url'),
      ...arraySchema('sectors', { required: true }),
      ...arraySchema('sector_industries', { required: true }),
      ...arraySchema('entitlements', { required: true }),
      ...textSchema('country', { required: true }),
    });
  },
  validateOnBlur: false,
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true);
    try {
      await props.saveCompany(values);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  },
})(
  ({
    values,
    errors,
    touched,
    handleChange,
    flattenSelectEvent,
    handleSubmit,
    handleBlur,
    edit,
    isSubmitting,
    setTouched,
    employees,
    contributors,
    posts,
    company,
    basePath,
    refresh,
    refreshing,
    percent,
    hiddenFields,
    onChangeFile,
    removeFile,
    addFile,
    onDragEnd,
    onDropFile,
    downloads,
    historyObject,
    saved,
    sectors,
    sector_industries,
    entitlements,
    countries,
    allowSend,
    onOpen,
    buy_type,
    last_sent_at,
    onOpenDrop,
  }) => {
    /**
     * @param {Object} e event object
     * @description set touch
     * */
    const changeWithTouchSet = (e) => {
      setTouched({ [e.target.name]: true });
      handleChange(e);
    };

    /**
     * @param {Object} e event object
     * @description set touch
     * */
    const onChangeMultiSelect = (e) => {
      setTouched({ [e.target.name]: true });
      handleChange({
        ...e,
        target: { name: e.target.name, value: e.target.value.map((value) => value.value) },
      });
    };

    /**
     * @param {Object} e event object
     * @description set touch
     * */
    const changeAuthor = (e) => {
      if (e.target.value === values.author_id_2) {
        handleChange({
          target: {
            name: 'author_id_2',
            value: '',
          },
        });
      }

      setTouched({ [e.target.name]: true });
      handleChange(e);
    };

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Prompt when={Object.keys(touched).length > 0 && !saved} history={historyObject} />
        <EditorTitle
          viewLink={edit ? `/app/company/${company.id}/${company.slug}` : null}
          title={
            edit ? (
              <FormattedMessage id="ADMIN.COMPANIES.EDIT_COMPANY" />
            ) : (
              <FormattedMessage id="ADMIN.COMPANIES.CREATE_COMPANY" />
            )
          }>
          <Button
            loading={isSubmitting}
            disabled={!(Object.keys(touched).length > 0 && !Object.keys(errors).length)}
            type="submit">
            <FormattedMessage id="ADMIN.SAVE" />
          </Button>
        </EditorTitle>
        <Grid fluid className="editor-content">
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                name="title"
                placeholderId="ADMIN.NAME"
                label={<FormattedMessage id="ADMIN.NAME" />}
                touched={touched.title}
                error={errors.title}
                value={values.title}
                onChange={changeWithTouchSet}
                onBlur={handleBlur}
                required
              />
            </Col>
            {!hiddenFields.includes('ticker') ? (
              <Col xs={12} md={6}>
                <FieldGroup
                  name="ticker"
                  placeholderId="ADMIN.POSTS.TICKER"
                  label={<FormattedMessage id="ADMIN.POSTS.TICKER" />}
                  touched={touched.ticker}
                  error={errors.ticker}
                  value={values.ticker}
                  onChange={changeWithTouchSet}
                  onBlur={handleBlur}
                  required
                />
              </Col>
            ) : null}
            <Col xs={12} md={4}>
              <Select
                name="share_owner_analyst_ids"
                label={<FormattedMessage id="ADMIN.SHARE_OWNER_ANALYSTS" />}
                value={values.share_owner_analyst_ids}
                touched={touched.share_owner_analyst_ids}
                error={errors.share_owner_analyst_ids}
                bottomMargin
                multi
                onChange={(e) => onChangeMultiSelect(e)}
                options={employees.map((employee) => ({
                  label: formatUsername(employee),
                  value: employee.id,
                }))}
              />
            </Col>
            {!hiddenFields.includes('author_id') ? (
              <Col xs={12} md={4}>
                <Select
                  name="author_id"
                  label={<FormattedMessage id="ADMIN.AUTHOR" />}
                  clearable={false}
                  value={values.author_id}
                  touched={touched.author_id}
                  error={errors.author_id}
                  bottomMargin
                  onBlur={handleBlur}
                  required
                  onChange={(e) => changeAuthor(flattenSelectEvent(e))}
                  options={employees.map((employee) => ({
                    label: formatUsername(employee),
                    value: employee.id,
                  }))}
                />
              </Col>
            ) : null}
            {!hiddenFields.includes('author_id') ? (
              <Col xs={12} md={4}>
                <Select
                  name="author_id_2"
                  label={<FormattedMessage id="ADMIN.AUTHOR" />}
                  clearable={false}
                  value={values.author_id_2}
                  touched={touched.author_id_2}
                  error={errors.author_id_2}
                  bottomMargin
                  onBlur={handleBlur}
                  onChange={(e) => changeWithTouchSet(flattenSelectEvent(e))}
                  options={employees
                    .filter((employee) => employee.id !== values.author_id)
                    .map((employee) => ({
                      label: formatUsername(employee),
                      value: employee.id,
                    }))}
                  disabled={!values.author_id}
                />
              </Col>
            ) : null}
            {!hiddenFields.includes('idea_source_id') ? (
              <Col xs={12} md={3}>
                <Select
                  name="edge_contributor_id"
                  label={<FormattedMessage id="ADMIN.CONTRIBUTOR" />}
                  clearable={false}
                  value={values.edge_contributor_id}
                  touched={touched.edge_contributor_id}
                  error={errors.edge_contributor_id}
                  bottomMargin
                  onBlur={handleBlur}
                  onChange={(e) => changeWithTouchSet(flattenSelectEvent(e))}
                  options={contributors.map(({ firstname, lastname, id }) => ({
                    label: `${firstname} ${lastname}`,
                    value: id,
                  }))}
                />
              </Col>
            ) : null}
            {!hiddenFields.includes('price_target') ? (
              <Col xs={12} md={6}>
                <FieldGroup
                  name="price_target"
                  placeholderId="ADMIN.PRICE_TARGET"
                  label={<FormattedMessage id="ADMIN.PRICE_TARGET" />}
                  touched={touched.price_target}
                  error={errors.price_target}
                  value={values.price_target}
                  onChange={changeWithTouchSet}
                  onBlur={handleBlur}
                />
              </Col>
            ) : null}
            {!hiddenFields.includes('recommendation') ? (
              <Col xs={12} md={6}>
                <FieldGroup
                  name="recommendation"
                  placeholderId="ADMIN.POSTS.RECOMMENDATION"
                  label={<FormattedMessage id="ADMIN.POSTS.RECOMMENDATION" />}
                  touched={touched.recommendation}
                  error={errors.recommendation}
                  value={values.recommendation}
                  onChange={changeWithTouchSet}
                  onBlur={handleBlur}
                />
              </Col>
            ) : null}
            <Col xs={12} md={6}>
              <FieldGroup
                name="video_url"
                placeholderId="ADMIN.COMPANIES.VIDEO_URL"
                label={<FormattedMessage id="ADMIN.COMPANIES.VIDEO_URL" />}
                touched={touched.video_url}
                error={errors.video_url}
                value={values.video_url}
                onChange={changeWithTouchSet}
                onBlur={handleBlur}
              />
            </Col>
            {!hiddenFields.includes('buy_type') ? (
              <Col xs={12} md={6}>
                <Select
                  value={values.buy_type}
                  clearable={false}
                  label={<FormattedMessage id="MAIN.BUY_TYPE" />}
                  name="buy_type"
                  touched={touched.buy_type}
                  error={errors.buy_type}
                  bottomMargin
                  onBlur={handleBlur}
                  onChange={(e) => changeWithTouchSet(flattenSelectEvent(e))}
                  options={Object.keys(BuyTypes).map((key) => ({
                    label: BuyTypes[key].name,
                    value: key,
                  }))}
                  required
                />
              </Col>
            ) : null}
            <Col xs={12} md={6}>
              <Select
                name="status"
                label={<FormattedMessage id="ADMIN.STATUS" />}
                clearable={false}
                value={values.status}
                touched={touched.status}
                error={errors.status}
                bottomMargin
                onBlur={handleBlur}
                onChange={(e) => changeWithTouchSet(flattenSelectEvent(e))}
                options={Object.keys(CompanyStatus).map((key) => ({
                  label: CompanyStatus[key].name,
                  value: key,
                }))}
                required
              />
            </Col>
            {!hiddenFields.includes('symbol') ? (
              <Col xs={12} md={6}>
                <FieldGroup
                  name="symbol"
                  label={<FormattedMessage id="ADMIN.COMPANIES.STOCK_ID" />}
                  touched={touched.symbol}
                  error={errors.symbol}
                  placeholderId="ADMIN.COMPANIES.STOCK_ID"
                  value={values.symbol}
                  onBlur={handleBlur}
                  onChange={changeWithTouchSet}
                />
              </Col>
            ) : null}
            {edit && !hiddenFields.includes('symbol') && values.symbol ? (
              <Col xs={12} md={6}>
                <FormGroup>
                  <ControlLabel>
                    <FormattedMessage id="MAIN.FORECAST_RETURN" />
                  </ControlLabel>
                  <InputGroup>
                    <FormControl readOnly type="text" value={formatPercent(percent)} />
                    <InputGroup.Addon>
                      <ActionIcon
                        className={classNames('fas fa-sync-alt', {
                          'fa-spin': refreshing,
                        })}
                        onClick={refresh}
                      />
                    </InputGroup.Addon>
                  </InputGroup>
                </FormGroup>
              </Col>
            ) : null}
            {!hiddenFields.includes('symbol') && !values.symbol ? (
              <Col xs={12} md={6}>
                <FieldGroup
                  name="percent"
                  label={<FormattedMessage id="MAIN.FORECAST_RETURN" />}
                  touched={touched.percent}
                  error={errors.percent}
                  placeholderId="MAIN.FORECAST_RETURN"
                  value={values.percent}
                  onBlur={handleBlur}
                  onChange={changeWithTouchSet}
                />
              </Col>
            ) : null}
            <Col xs={12} md={6}>
              <Select
                name="sectors"
                label={<FormattedMessage id="ADMIN.SECTORS.TITLE" />}
                value={values.sectors}
                touched={touched.sectors}
                error={errors.sectors}
                bottomMargin
                required
                multi
                onChange={(e) => onChangeMultiSelect(e)}
                options={
                  sectors &&
                  sectors.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <Select
                name="country"
                label={<FormattedMessage id="ADMIN.COMPANIES.COUNTRY" />}
                clearable={false}
                value={values.country}
                touched={touched.country}
                error={errors.country}
                bottomMargin
                onBlur={handleBlur}
                required
                onChange={(e) => changeWithTouchSet(flattenSelectEvent(e))}
                options={
                  countries &&
                  countries.map((country) => ({
                    label: country,
                    value: country,
                  }))
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <Select
                name="sector_industries"
                label={<FormattedMessage id="ADMIN.SECTORS.INDUSTRY_SECTORS" />}
                value={values.sector_industries}
                touched={touched.sector_industries}
                error={errors.sector_industries}
                bottomMargin
                required
                multi
                onChange={(e) => onChangeMultiSelect(e)}
                options={
                  sector_industries &&
                  sector_industries.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <Select
                name="entitlements"
                label={<FormattedMessage id="ADMIN.SECTORS.ENTITLEMENTS" />}
                value={values.entitlements}
                touched={touched.entitlements}
                error={errors.entitlements}
                bottomMargin
                required
                multi
                onChange={(e) => onChangeMultiSelect(e)}
                options={
                  entitlements &&
                  entitlements.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))
                }
              />
            </Col>
            {!hiddenFields.includes('downloads') ? (
              <Col xs={12}>
                <ControlLabel>
                  <FormattedMessage id="ADMIN.POSTS.DOWNLOADS" />
                </ControlLabel>
                <Downloads
                  name="downloads"
                  onChange={(e, index) => {
                    setTouched({ [e.target.name]: true });
                    onChangeFile(e, index);
                  }}
                  removeFile={(index) => {
                    setTouched({ downloads: true });
                    removeFile(index);
                  }}
                  addFile={() => {
                    setTouched({ downloads: true });
                    addFile();
                  }}
                  onDropImage={(files, index, type) => {
                    setTouched({ downloads: true });
                    onDropFile(files, index, type);
                  }}
                  onDragEnd={(params) => {
                    setTouched({ downloads: true });
                    onDragEnd(params);
                  }}
                  files={downloads}
                  maxFiles={1}
                />
              </Col>
            ) : null}
            {allowSend && buy_type !== Buys.NO_RECOMMENDATION ? (
              <Col xs={12}>
                <Button onClick={onOpen}>
                  <FormattedMessage id="ADMIN.SEND_MODELS.BUTTON" />
                </Button>
              </Col>
            ) : null}
            {buy_type === Buys.NO_RECOMMENDATION ? (
              <Col xs={12}>
                <Button onClick={onOpenDrop}>
                  <FormattedMessage id="ADMIN.SEND_MODELS.DROP_BUTTON" />
                </Button>
              </Col>
            ) : null}
            <Col xs={12} className="mt-3">
              <em>
                <FormattedMessage id="ADMIN.SEND_MODELS.LAST_SENT" />
                {last_sent_at ? (
                  <FormattedDateParts
                    value={last_sent_at}
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    weekday="long">
                    {(parts) => (
                      <>{`${parts[8].value}:${
                        parts[10].value
                      }${parts[12].value.toLocaleLowerCase()}, ${parts[0].value} ${
                        parts[4].value
                      } ${parts[2].value} ${parts[6].value}`}</>
                    )}
                  </FormattedDateParts>
                ) : null}
              </em>
            </Col>
          </Row>
        </Grid>
      </form>
    );
  }
);

class CompaniesEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    refreshPercent: PropTypes.func.isRequired,
    saveCompany: PropTypes.func.isRequired,
    modifyCompany: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    match: match.isRequired,
    history: history.isRequired,
    employees: PropTypes.array,
    posts: PropTypes.array,
    contributors: PropTypes.array,
    getEmployees: PropTypes.func,
    getContributors: PropTypes.func,
    getPosts: PropTypes.func,
    hiddenFields: PropTypes.array,
    getSectors: PropTypes.func,
    getIndustrySectors: PropTypes.func,
    getEntitlements: PropTypes.func,
    sectors: PropTypes.array,
    sector_industries: PropTypes.array,
    entitlements: PropTypes.array,
    fetchCountries: PropTypes.func.isRequired,
  };

  edit = !!this.props.match.params.id;
  state = {
    company: {
      title: '',
      price_target: '',
      video_url: '',
      buy_type: '',
      symbol: '',
      status: '',
      description: '',
      author_id: '',
      author_id_2: '',
      ticker: '',
      recommendation: '',
      idea_source_id: '',
      downloads: [],
      sectors: [],
      sector_industries: [],
      entitlements: [],
      share_owner_analyst_ids: [],
      country: '',
      drop_coverage_last_sent: null,
    },
    countries: [],
    saved: false,
    loading: true,
    refreshing: false,
    show: false,
    allowSend: false,
    showDrop: false,
  };

  /**
   * @returns {Object} return new file object.
   */
  static getFile() {
    return { custom_properties: { title: '', description: '' }, data: {}, file_name: '' };
  }

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();
    const dataPromises = this.getDataPromises();
    if (this.edit) {
      dataPromises.unshift(this.props.getCompany(this.props.match.params.id));
    }

    const [company] = await Promise.all(dataPromises);

    const countries = await this.props.fetchCountries();

    const state = { loading: false, countries };
    if (company && this.edit) {
      state.company = {
        ...company,
        sectors: company.sectors?.map((sector) => sector.id) || [],
        sector_industries: company.sector_industries?.map((sector) => sector.id) || [],
        entitlements: company.entitlements?.map((entitlement) => entitlement.id) || [],
        share_owner_analyst_ids: company.share_owner_analysts?.map((analyst) => analyst.id) || [],
      };
      state.allowSend = company.downloads && company.downloads.length > 0;
    }

    this.setState(state);
    this.props.hideLoader();
  }

  /**
   * @returns {array} dataPromises Api
   * */
  getDataPromises() {
    const dataPromises = [];
    const listApi = [
      'getEmployees',
      'getContributors',
      'getPosts',
      'getSectors',
      'fetchCountries',
      'getIndustrySectors',
      'getEntitlements',
    ];

    for (const api of listApi) {
      if (this.props[api]) {
        dataPromises.push(this.props[api]());
      }
    }
    return dataPromises;
  }

  /**
   * @param {Event} e Event object
   * @returns {Object} Fake Event object
   */
  flattenSelectEvent = (e) => {
    return {
      ...e,
      target: { ...e.target, value: e.target.value ? e.target.value.value : '' },
    };
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = (e) => {
    this.setState({ company: { ...this.state.company, [e.target.name]: e.target.value } });
  };

  /**
   * @param {Object} company Company data.
   */
  save = async (company) => {
    this.setState({ saved: true });
    company.downloads = this.state.company.downloads;

    for (const hiddenField of this.props.hiddenFields) {
      delete company[hiddenField];
    }

    if (typeof this.state.company.id !== 'undefined') {
      const savedCompany = await this.props.modifyCompany(this.state.company.id, company);
      this.setState({ company: savedCompany });
      if (savedCompany.coverage_changed) {
        this.onOpenDrop();
      }
    } else {
      const { id, downloads } = await this.props.saveCompany(company);
      this.setState({ company: { ...this.state.company, id, downloads } });
      this.props.history.push(
        `/app/admin/${this.props.basePath || 'analyst'}/companies/${id}/edit`
      );
    }
    toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);

    if (
      this.state.company.downloads &&
      this.state.company.downloads.length > 0 &&
      company.buy_type !== Buys.NO_RECOMMENDATION
    ) {
      this.setState({ allowSend: true });
      this.onOpen();
    }

    this.setState({ company: { ...this.state.company, buy_type: company.buy_type } });
  };

  refreshPercent = async () => {
    if (!this.state.refreshing) {
      this.setState({ refreshing: true });

      const company = await this.props.refreshPercent(this.state.company.id);

      this.setState({ refreshing: false, company });
    }
  };

  addFile = () => {
    const downloads = this.state.company.downloads ? this.state.company.downloads : [];
    const company = {
      ...this.state.company,
      downloads: [...downloads, CompaniesEditor.getFile()],
    };

    this.setState({ company });
  };

  onChangeFile = (e, index) => {
    const company = {
      ...this.state.company,
      downloads: [...this.state.company.downloads],
    };

    company.downloads[index].custom_properties[e.target.name] = e.target.value;

    this.setState({ company, allowSend: false });
  };

  removeFile = (index) => {
    const downloads = this.state.company.downloads.map((data) => data);
    downloads.splice(index, 1);
    const company = { ...this.state.company, downloads };

    this.setState({ company, allowSend: false });
  };

  onDropFile = (files, index, type) => {
    if (!!index || (index === 0 && type === 'downloads')) {
      // downloads
      const company = {
        ...this.state.company,
        downloads: [...this.state.company.downloads],
      };

      if (files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          company.downloads[index].data = reader.result;
          company.downloads[index].file_name = files[0].name;
          company.downloads[index].mime_type = files[0].type;
          delete company.downloads[index].id;
          this.setState({ company });
        };
      }
    }
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    const downloads = arrayMove(this.state.company.downloads, oldIndex, newIndex);
    for (let i = 0; i < downloads.length; i++) {
      downloads[i].order_column = i;
    }
    this.setState({
      company: {
        ...this.state.company,
        downloads,
      },
    });
  };

  onOpen = () => {
    this.setState({ show: true });
  };

  onClose = () => {
    this.setState({ show: false });
  };

  onOpenDrop = () => {
    this.setState({ showDrop: true });
  };

  onCloseDrop = () => {
    this.setState({ showDrop: false });
  };

  sendModels = async () => {
    try {
      await SendModelService.send({
        companyId: this.state.company.id,
        mediaId: this.state.company.downloads[0].id,
      });
      this.setState({ show: false });
      toast.success(<FormattedMessage id="ADMIN.SEND_MODELS.SUCCESS" />);
    } catch (e) {
      if (e.response.data.error) {
        e.ignoreToast = true;
        toast.error(<FormattedMessage id={e.response.data.error} />);
      }
    }
  };

  sendDropEmail = async () => {
    try {
      const { drop_coverage_last_sent } = await CompaniesService.sendDropEmail(
        this.state.company.id
      );

      const company = {
        ...this.state.company,
        drop_coverage_last_sent,
      };

      this.setState({ showDrop: false, company });
      toast.success(<FormattedMessage id="ADMIN.SEND_MODELS.DROP_SUCCESS" />);
    } catch (e) {
      if (e.response.data.error) {
        e.ignoreToast = true;
        toast.error(<FormattedMessage id={e.response.data.error} />);
      }
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { company } = this.state;
    return (
      <div>
        {!this.state.loading ? (
          <CompaniesEditorForm
            company={company}
            employees={this.props.employees}
            posts={this.props.posts}
            contributors={this.props.contributors}
            flattenSelectEvent={this.flattenSelectEvent}
            edit={typeof this.state.company.id !== 'undefined'}
            saveCompany={this.save}
            basePath={this.props.basePath}
            refresh={this.refreshPercent}
            refreshing={this.state.refreshing}
            percent={company.percent}
            hiddenFields={this.props.hiddenFields}
            onChangeFile={this.onChangeFile}
            removeFile={this.removeFile}
            addFile={this.addFile}
            onDropFile={this.onDropFile}
            onDragEnd={this.onDragEnd}
            downloads={company.downloads}
            saved={this.state.saved}
            historyObject={this.props.history}
            sectors={this.props.sectors}
            sector_industries={this.props.sector_industries}
            entitlements={this.props.entitlements}
            countries={this.state.countries}
            onOpen={this.onOpen}
            allowSend={this.state.allowSend}
            buy_type={this.state.company.buy_type}
            last_sent_at={this.state.company.drop_coverage_last_sent}
            coverage_changed={this.state.coverage_changed}
            onOpenDrop={this.onOpenDrop}
          />
        ) : null}
        <Modal
          show={this.state.show}
          onHide={this.onClose}
          onSubmit={this.sendModels}
          onSecondary={this.onClose}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.SEND_MODELS.SEND" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.SEND_MODELS.CANCEL" />,
          }}
          size="normal">
          <FormattedMessage id="ADMIN.SEND_MODELS.QUESTION" />
        </Modal>
        <Modal
          show={this.state.showDrop}
          onHide={this.onCloseDrop}
          onSubmit={this.sendDropEmail}
          onSecondary={this.onCloseDrop}
          data={{
            modalSubmitText: <FormattedMessage id="ADMIN.SEND_MODELS.SEND" />,
            modalSecondaryText: <FormattedMessage id="ADMIN.SEND_MODELS.CANCEL" />,
          }}
          size="normal">
          <FormattedMessage id="ADMIN.SEND_MODELS.DROP_QUESTION" />
        </Modal>
      </div>
    );
  }
}

export default CompaniesEditor;
