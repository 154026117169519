import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withRouter } from 'react-router-dom';

import { match, history } from 'common';
import { Html, Img, LightBox, RelatedPosts, TextEditorView, Svg } from 'modules/shared';
import Env from 'env';

import styles from './ShortScreen.scss';

class ShortScreen extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getShortScreen: PropTypes.func.isRequired,
    shortScreen: PropTypes.object.isRequired,
    getPostsPage: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    setEditItem: PropTypes.func.isRequired,
    basePath: PropTypes.string,
    intl: PropTypes.object,
    loading: PropTypes.bool,
    match,
    history,
  };

  /**
   * @description Gets the data for the page.
   */
  async componentDidMount() {
    try {
      this.props.showLoader();
      this.props.setEditItem({
        text: this.props.intl.formatMessage({ id: 'ADMIN.SHORT_SCREEN.EDIT_SHORT_SCREEN' }),
        link: `/app/admin/content/short-screen`,
      });
      await this.props.getShortScreen();
    } finally {
      this.props.hideLoader();
    }
  }

  /**
   * @param {Object} params Injected params.
   * @returns {Promise}
   */
  fetchPosts = (params) => {
    return this.props.getPostsPage({
      ...params,
      onlyshortscreen: true,
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { shortScreen, basePath, bookmarkPost, deleteBookmark, loading } = this.props;

    return (
      <div>
        <FormattedMessage id="ADMIN.SHORT_SCREEN.TITLE">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {shortScreen ? (
          <Grid fluid className="short-screen">
            <div className="d-flex flex-column">
              <Row className="white-background d-flex">
                <div className="d-flex flex-wrap">
                  <Col xs={12} sm={12} md={7} className="short-screen-detail-section">
                    <Col xs={12} sm={12} className="p-0">
                      <Col xs={12} sm={12} className="short-screen-title">
                        <Svg name="short_screen_logo" />
                      </Col>
                      <Col xs={12} sm={12} className="p-0 d-flex justify-center">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={Env.S3_URL + shortScreen.featured_image_path}>
                          <Img
                            className="short-screen-image"
                            alt="short-screen"
                            src={Env.S3_URL + shortScreen.featured_image_path}
                          />
                        </a>
                        {shortScreen.featured_image_path ? (
                          <LightBox containerClassName="short-screen" />
                        ) : null}
                      </Col>
                    </Col>
                  </Col>
                  <Col xs={12} sm={12} md={5} className="short-screen-info-section">
                    <Col xs={12} sm={12} className="short-info-item">
                      <TextEditorView
                        fluid={true}
                        data={{ text: shortScreen.description }}
                        gridClass="pl-0"
                      />
                    </Col>
                    {shortScreen.video_url ? (
                      <Col xs={12} sm={12} className="short-info-item">
                        <Html className="video-container" content={shortScreen.vimeo_data.embed} />
                      </Col>
                    ) : null}
                  </Col>
                </div>
              </Row>
              <RelatedPosts
                bookmarkPost={bookmarkPost}
                deleteBookmark={deleteBookmark}
                fetchData={this.fetchPosts}
                basePath={basePath}
                defaultImage={Env.ANALYST_DEFAULT_IMAGE}
                showTotal
                moreWidth
                hide={loading}
              />
            </div>
          </Grid>
        ) : !this.props.loading ? (
          <div className="d-flex justify-center align-center model-portfolio-empty-state">
            <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.EMPTY_STATE" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(withRouter(ShortScreen)));
