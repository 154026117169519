import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  match,
  history,
  systemSettingsTypeOptions,
  commonFileUpload,
  TEXT_EDITOR_ID,
  INPUT_ID,
  IMAGE_UPLOAD_ID,
  FILE_UPLOAD_ID,
} from 'common';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getSetting, saveSetting, modifySetting } from 'modules/main/actions/system-settings';
import { commonUploadFile } from 'modules/admin/main/actions/file-upload';
import {
  EditorTitle,
  Button,
  FieldGroup,
  FileUpload,
  Select,
  TextEditor,
} from 'modules/admin/shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateSchema, textSchema } from 'common/shemaValidator';
import Env from 'env';

class SettingsEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getSetting: PropTypes.func.isRequired,
    saveSetting: PropTypes.func.isRequired,
    modifySetting: PropTypes.func.isRequired,
    commonUploadFile: PropTypes.func.isRequired,
    history,
    match,
  };

  edit = !!this.props.match.params.id;
  state = {
    systemSetting: {
      key: '',
      value: '',
      description: '',
      type: null,
    },
    errors: {},
    isSubmitting: false,
    loading: true,
    isValid: false,
    isReady: true,
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();

    if (this.edit) {
      const systemSettings = await this.props.getSetting(this.props.match.params.id);
      const state = {
        loading: false,
        systemSetting: { ...systemSettings },
        isValid: true,
      };
      this.setState(state);
    } else {
      this.setState({ loading: false });
    }
    this.props.hideLoader();
  }

  /**
   * @param {Object} e event
   * @param {boolean} withoutNavigation indicate navigation
   * @description save systemSetting changes
   */
  save = async (e, withoutNavigation) => {
    this.setState({ isSubmitting: true });

    const systemSetting = { ...this.state.systemSetting };
    try {
      if (typeof systemSetting.id !== 'undefined') {
        await this.props.modifySetting(systemSetting.key, systemSetting);
      } else if (!withoutNavigation) {
        const { id, key } = await this.props.saveSetting(systemSetting);
        this.setState({ systemSetting: { ...this.state.systemSetting, id } });
        this.props.history.push(`/app/admin/system/settings/${key}/edit`);
      }
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  /**
   * @returns {boolean} editor is valid.
   */
  isValidForm = async () => {
    const schema = Yup.object().shape({
      ...textSchema('key', { required: true }),
      value: Yup.string().nullable(),
      ...textSchema('description'),
    });

    return await schema.isValid(this.state.systemSetting);
  };

  onDropFile = async (files) => {
    const { path } = await commonFileUpload(files, this.props.commonUploadFile);
    this.setState({
      systemSetting: {
        ...this.state.systemSetting,
        value: path,
      },
    });
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      {
        systemSetting: {
          ...this.state.systemSetting,
          [name]: value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm();
        try {
          await validateSchema(
            name === 'value' ? { value: Yup.string().nullable() } : textSchema(name),
            {
              [name]: value,
            }
          );
          errors[name] = '';
        } catch (validationError) {
          errors[name] = validationError.errors[0];
        }

        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  setReadyState = (isReady) => {
    this.setState({ isReady });
  };

  /**
   * @param {Event} e Event object
   * @returns {Object} Fake Event object
   */
  flattenSelectEvent = (e) => {
    return {
      ...e,
      target: { ...e.target, value: e.target.value ? e.target.value.value : '' },
    };
  };

  deleteFile = async () => {
    this.setState(
      {
        systemSetting: {
          ...this.state.systemSetting,
          value: null,
        },
      },
      async () => {
        this.save(null, true);
      }
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { systemSetting, errors, loading, isSubmitting } = this.state;
    return (
      <div>
        {!loading ? (
          <form noValidate>
            <EditorTitle
              title={
                typeof systemSetting.id !== 'undefined' ? (
                  <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.EDIT_SYSTEM_SETTING" />
                ) : (
                  <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.CREATE_SYSTEM_SETTING" />
                )
              }>
              <Button
                loading={isSubmitting}
                onClick={this.save}
                type="button"
                disabled={!this.state.isValid || isSubmitting || !this.state.isReady}>
                <FormattedMessage id="ADMIN.SAVE" />
              </Button>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12} sm={6}>
                  <Select
                    name="type"
                    label={<FormattedMessage id="ADMIN.TYPE" />}
                    clearable={false}
                    bottomMargin
                    value={systemSetting.type}
                    onChange={(e) => this.onChange(this.flattenSelectEvent(e))}
                    options={systemSettingsTypeOptions.map(({ name, id }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="description"
                    componentClass="textarea"
                    className="touched"
                    error={errors.description}
                    placeholderId="ADMIN.DESCRIPTION"
                    label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
                    value={systemSetting.description}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="key"
                    className="touched"
                    placeholderId="ADMIN.SYSTEM_SETTINGS.KEY"
                    label={<FormattedMessage id="ADMIN.SYSTEM_SETTINGS.KEY" />}
                    error={errors.key}
                    value={systemSetting.key}
                    disabled={this.edit}
                    onChange={this.onChange}
                    required
                  />
                </Col>
                {/*text editor field*/}
                {+systemSetting.type === TEXT_EDITOR_ID ? (
                  <Col xs={12} sm={12}>
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.VALUE" />
                    </ControlLabel>
                    <TextEditor
                      name="value"
                      className="post-html-content"
                      text={systemSetting.value}
                      setReadyState={this.setReadyState}
                      handleChange={this.onChange}
                    />
                  </Col>
                ) : null}
                {/*input field*/}
                {+systemSetting.type === INPUT_ID ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="value"
                      className="touched"
                      error={errors.value}
                      placeholderId="ADMIN.SYSTEM_SETTINGS.VALUE"
                      label={<FormattedMessage id="ADMIN.SYSTEM_SETTINGS.VALUE" />}
                      value={systemSetting.value}
                      onChange={this.onChange}
                    />
                  </Col>
                ) : null}

                {/*file full url*/}
                {+systemSetting.type === IMAGE_UPLOAD_ID ||
                +systemSetting.type === FILE_UPLOAD_ID ? (
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      placeholderId="ADMIN.SYSTEM_SETTINGS.FILE_URL"
                      label={<FormattedMessage id="ADMIN.SYSTEM_SETTINGS.FILE_URL" />}
                      value={
                        +systemSetting.type === FILE_UPLOAD_ID
                          ? Env.S3_URL + systemSetting.value
                          : systemSetting.value
                      }
                      error={errors.value}
                      readOnly
                    />
                  </Col>
                ) : null}

                {/*image upload field*/}
                {+systemSetting.type === IMAGE_UPLOAD_ID ||
                +systemSetting.type === FILE_UPLOAD_ID ? (
                  <Col xs={12} sm={6} className="image-header">
                    <FormGroup className="image-preview-container">
                      <ControlLabel>
                        <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.VALUE" />
                      </ControlLabel>
                      <FileUpload
                        accept={
                          +systemSetting.type === IMAGE_UPLOAD_ID
                            ? 'image/jpeg, image/png'
                            : 'application/pdf, .mp3, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx'
                        }
                        file={systemSetting.value}
                        deleteFile={this.deleteFile}
                        onDropFile={(files) => this.onDropFile(files)}
                      />
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    roles: app.roles,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getSetting,
      saveSetting,
      modifySetting,
      commonUploadFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SettingsEditor);
