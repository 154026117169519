import { combineReducers } from 'redux';

import ideas from './ideas';
import companies from './companies';
import posts from './posts';
import questions from './questions';
import recommendations from './recommendations';
import bookmarks from './bookmarks';
import categories from './categories';
import tags from './tags';
import investmentStyles from './investment-style';
import marketcap from './marketcap';
import modelPortfolio from './model-portfolio';
import geographies from './geographies';
import shortScreen from './short-screen';
import surveys from './surveys';
import walkThrough from './walk-through';
import sentimentTracker from './sentiment-tracker';
import sectors from './sectors';
import adminCompanies from './admin-companies';
import sector_industries from './sector-industries';
import entitlements from './entitlements';

export default combineReducers({
  ideas,
  companies,
  adminCompanies,
  posts,
  questions,
  recommendations,
  bookmarks,
  categories,
  tags,
  investmentStyles,
  marketcap,
  modelPortfolio,
  geographies,
  shortScreen,
  surveys,
  walkThrough,
  sentimentTracker,
  sectors,
  sector_industries,
  entitlements,
});
