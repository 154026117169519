import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputs } from 'modules/admin/shared/components';
import { TextEditor } from 'modules/admin/shared';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FileUpload } from 'modules/admin/shared';
import { isValidSchema, pagesFileUpload } from 'common';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';
import { FormattedMessage } from 'react-intl';

const titleSchema = {
  title: Yup.string()
    .trim()
    .required(),
};

const descriptionSchema = {
  description: Yup.string()
    .trim()
    .required(),
};

class SingleTextBox extends Component {
  static componentType = 'single-text-box';
  static blockName = 'Single Text Box';
  static icon = 'fas fa-building';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    pagesUploadFile: PropTypes.func.isRequired,
  };

  state = { ...SingleTextBox.getWorkSection(), loaded: false };

  /**
   * @returns {Object} Empty opportunity object.
   */
  static getWorkSection() {
    return {
      title: '',
      description: '',
      image: {},
      fileTitle: '',
      fileAlt: '',
      loading: false,
    };
  }

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState({ ...this.props.data, loaded: true });
  }

  /**
   * @returns {boolean} form is valid.
   */
  async isValidForm() {
    const schema = Yup.object().shape({
      ...titleSchema,
      ...descriptionSchema,
    });

    return await schema.isValid(this.state);
  }

  onDropImage = (files) => {
    this.setState({ image: files.length > 0 ? files[0] : null, loading: true }, async () => {
      const { title, description } = this.state;
      const valid = await this.isValidForm();

      const { path } = await pagesFileUpload(
        this.props.site,
        this.props.slug,
        files,
        this.props.pagesUploadFile
      );

      this.setState({ loading: false });
      this.props.onChange({ image: path, title, description, valid });
    });
  };

  deleteFile = async () => {
    this.setState({
      image: null,
    });
  };

  onChange = async (e) => {
    const data = { [e.target.name]: e.target.value };
    const { isValid } = await isValidSchema(titleSchema, {
      title: e.target.value,
    });

    this.setState({ ...data, validTitle: isValid }, async () => {
      const { loading, ...state } = this.state;
      const valid = await this.isValidForm();
      this.props.onChange({ ...state, valid });
    });
  };

  handleEditorChange = async (text) => {
    const { isValid } = await isValidSchema(descriptionSchema, {
      description: text,
    });

    this.setState({ description: text, validDescription: isValid }, async () => {
      const { image, title, description } = this.state;
      const valid = await this.isValidForm();
      this.props.onChange({ image, title, description, valid });
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        <FormInputs
          ncols={['col-md-12']}
          properties={[
            {
              label: <FormattedMessage id="ADMIN.TITLE" />,
              type: 'text',
              error: this.state.validTitle === undefined ? false : !this.state.validTitle,
              bsClass: 'form-control',
              placeholderId: 'ADMIN.TITLE',
              name: 'title',
              value: this.state.title,
              onChange: this.onChange,
            },
          ]}
        />

        <FormGroup className="image-preview-container">
          <ControlLabel>Image</ControlLabel>
          <FileUpload
            file={this.state.image}
            fileTitle={this.state.fileTitle}
            fileAlt={this.state.fileAlt}
            onDropFile={this.onDropImage}
            deleteFile={this.deleteFile}
            onChange={this.onChange}
            loading={this.state.loading}
          />
        </FormGroup>

        <TextEditor
          error={this.state.validDescription === undefined ? false : !this.state.validDescription}
          handleChange={(e) => {
            const text = e.target.value;
            this.handleEditorChange(text);
          }}
          text={this.state.description}
          loaded={this.state.loaded}
        />
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pagesUploadFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SingleTextBox);
