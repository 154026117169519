import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * SyncPopupContent component renders the content for the sync popup.
 * @param {Object} props - The component props.
 * @param {string} props.postSharePointSyncDate - The date when the post was last synced with SharePoint.
 * @param {Object} props.outOfSyncData - The data that is out of sync.
 * @param {string} props.outOfSyncData.sharepoint_file_updated_at - The date when the SharePoint file was last updated.
 * @param {Array} props.outOfSyncData.posts - The list of posts that are out of sync.
 * @returns {JSX.Element} The rendered component.
 */
export default function SyncPopupContent({ postSharePointSyncDate, outOfSyncData }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormattedMessage
        id="ADMIN.OUT_OF_SYNC_POPUP.QUESTION"
        values={{
          syncDate1: outOfSyncData?.sharepoint_file_updated_at,
          syncDate2: postSharePointSyncDate || '',
        }}
      />
      <br />
      <FormattedMessage
        id="ADMIN.OUT_OF_SYNC_POPUP.DUPLICATE_DOCUMENT"
        values={{
          link: outOfSyncData?.posts ? (
            <ul>
              {outOfSyncData?.posts.map((post) => (
                <li key={post.id}>
                  <a
                    href={`/app/admin/content/posts/${post.id}/edit`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {post.title}
                  </a>
                  <p>{post.last_sync_at}</p>
                </li>
              ))}
            </ul>
          ) : (
            ''
          ),
        }}
      />
    </div>
  );
}

SyncPopupContent.propTypes = {
  postSharePointSyncDate: PropTypes.string,
  outOfSyncData: PropTypes.shape({
    sharepoint_file_updated_at: PropTypes.string,
    posts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        last_sync_at: PropTypes.string.isRequired,
      })
    ),
  }),
};
