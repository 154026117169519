import React, { Component } from 'react';
import { ControlLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import { ErrorIconContainer } from 'modules/admin/shared';

import styles from './Select.scss';

class Select extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onNewOptionClick: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    bottomMargin: PropTypes.bool,
    className: PropTypes.string,
    touched: PropTypes.bool,
    allowCreate: PropTypes.bool,
    error: PropTypes.any,
  };

  onChange = (selectedValue) => {
    if (this.props.onChange) {
      this.props.onChange({
        target: { name: this.props.name, value: selectedValue },
        persist: () => {},
      });
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      label,
      onChange,
      bottomMargin,
      className,
      touched,
      error,
      allowCreate,
      onNewOptionClick,
      ...props
    } = this.props;

    return (
      <div
        className={classNames(
          'react-select-container',
          { 'bottom-margin': bottomMargin, touched, 'has-error': error, multi: props.multi },
          className
        )}>
        {label ? (
          <ControlLabel>
            {label}
            {props.required ? '*' : null}
          </ControlLabel>
        ) : null}
        <span className="just-asterisk">{!label ? (props.required ? '*' : null) : null}</span>
        {allowCreate ? (
          <ReactSelect.Creatable
            id={this.props.name}
            onChange={this.onChange}
            onNewOptionClick={onNewOptionClick}
            {...props}
          />
        ) : (
          <ReactSelect id={this.props.name} onChange={this.onChange} {...props} />
        )}
        <ErrorIconContainer error={error} touched={touched} />
      </div>
    );
  }
}

export default withStyles(styles)(Select);
