import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  Header,
  ProfileDropdown,
  MegaMenu,
  Svg,
  headerContent,
  AccountManagerModal,
  Button,
  SvgIcon,
} from 'modules/shared';
import { Roles, CanAccess } from 'modules/shared';

class AnalystAppHeader extends Component {
  static propTypes = {
    onSidebarMenuClick: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    onSearchClick: PropTypes.func.isRequired,
    showMegaMenu: PropTypes.bool,
    windowWidth: PropTypes.number,
    sideMenuOpen: PropTypes.bool,
    editItem: PropTypes.object,
    profile: PropTypes.object.isRequired,
    toggleLoginForm: PropTypes.func,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="navbar-container">
        <Header fluid className="analyst-app-header">
          <CanAccess
            allowedRoles={[Roles.AnalystSubscriber, Roles.AnalystEditor, Roles.AnalystAdmin]}>
            <i
              className={classNames(
                this.props.sideMenuOpen ? 'icon-hamburger-open' : 'icon-hamburger-closed',
                'light side-menu-toggle'
              )}
              onClick={this.props.onSidebarMenuClick}
            />
          </CanAccess>
          <NavLink to={`/app`} className="title logo">
            <Svg name="the-analyst-logo-light" className="the-analyst-logo-light" />
          </NavLink>
          <div className="right-icons d-flex align-center">
            <div className="hidden-xs">
              <AccountManagerModal />
            </div>
            <Button sm className="white hidden-xs" onClick={this.props.onSearchClick}>
              <SvgIcon className="icon-hover small" name="search" />
              <FormattedMessage id="MAIN.SEARCH_BUTTON" />
            </Button>
            <span className="hidden-xs">
              <ProfileDropdown editItem={this.props.editItem} profilePath="/app" />
            </span>
            {/*<i onClick={this.props.onToggle} className="toggle-icon icon-hamburger light" />*/}
          </div>
        </Header>
        <MegaMenu
          showMenu={this.props.showMegaMenu}
          toggleMenu={this.props.onToggle}
          toggleAnalystLoginForm={() => this.props.toggleLoginForm('showAnalystLoginForm')}
          toggleEdgeLoginForm={() => this.props.toggleLoginForm('showEdgeLoginForm')}
        />
      </div>
    );
  }
}

export default headerContent(AnalystAppHeader);
