import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { match, location, history } from 'common';
import { CreatePasswordForm } from 'modules/shared';

class CreatePassword extends Component {
  static propTypes = {
    createPassword: PropTypes.func.isRequired,
    match,
    location,
    history,
  };

  state = {
    tokenErrorMessage: '',
  };

  /**
   * @param {string} tokenErrorMessage id
   * @description set error message
   * */
  setTokenErrorMessage = (tokenErrorMessage) => {
    this.setState({ tokenErrorMessage });
  };

  /**
   * @returns {JSX.Element} - Our People component
   */
  render() {
    return (
      <div className="analyst-home">
        <CreatePasswordForm
          createPassword={this.props.createPassword}
          token={this.props.match.params.token}
          tokenErrorMessage={this.state.tokenErrorMessage}
          setTokenErrorMessage={this.setTokenErrorMessage}
          query={this.props.location.search}
          newRedirect={`/app/profile/analyst-preferences`}
          forgotRedirect={`/app`}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default CreatePassword;
