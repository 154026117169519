import { BaseBuys, SellNoRecommendationBuys } from 'modules/shared/models';

export const Buys = {
  ...BaseBuys,
  ...SellNoRecommendationBuys,
};

export const BuyTypes = {
  [Buys.LONG]: {
    name: 'Long',
    icon: 'arrow-blue',
  },
  [Buys.SHORT]: {
    name: 'Short',
    icon: 'arrow-red',
  },
  [Buys.NO_RECOMMENDATION]: {
    name: 'No Current Recommendation',
  },
};
