import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withRouter } from 'react-router-dom';

import { match, history } from 'common';
import { Html, TextEditorView } from 'modules/shared/components';

import styles from './WalkThrough.scss';

class WalkThrough extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getWalkThrough: PropTypes.func.isRequired,
    walkThrough: PropTypes.object.isRequired,
    setEditItem: PropTypes.func.isRequired,
    intl: PropTypes.object,
    match,
    history,
  };

  /**
   * @description Gets the data for the page.
   */
  async componentDidMount() {
    this.props.setEditItem({
      text: this.props.intl.formatMessage({ id: 'ADMIN.WALK_THROUGH.EDIT_WALK_THROUGH' }),
      link: `/app/admin/content/walk-through`,
    });
    await this.props.getWalkThrough();
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { walkThrough } = this.props;

    return (
      <div>
        <FormattedMessage id="ADMIN.WALK_THROUGH.TITLE">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {walkThrough ? (
          <Grid fluid className="walk-through">
            <Row className="white-background mt-0">
              <div className="d-flex justify-center flex-wrap">
                <Col xs={12} sm={9} md={7}>
                  <div className="walk-through-title text-center">{walkThrough.title}</div>
                </Col>
                {walkThrough.video_url ? (
                  <Col xs={12} sm={9} md={7}>
                    <Html className="video-container" content={walkThrough.vimeo_data.embed} />
                  </Col>
                ) : null}
                <Col xs={12} sm={9} md={7}>
                  <TextEditorView
                    fluid={true}
                    data={{ text: walkThrough.description }}
                    gridClass="pl-0"
                    breakWord
                  />
                </Col>
              </div>
            </Row>
          </Grid>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(withRouter(WalkThrough)));
