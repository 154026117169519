import React, { Component } from 'react';
import moment from 'moment/moment';
import { Grid, Row, Col, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { FieldGroup } from 'modules/admin/shared';
import { DateTimePicker } from 'modules/shared';
import Env from 'env';

class FilterSection extends Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    search: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { onSearch, search, from, to } = this.props;
    return (
      <Grid fluid className="analytics-filter-section">
        <Row>
          <Col xs={6}>
            <ControlLabel>
              <FormattedMessage id="ADMIN.ANALYTICS.DATE_FROM" />
            </ControlLabel>
            <DateTimePicker
              selected={from}
              onChange={(t) => {
                onSearch(null, 'from', moment(t).isValid() ? moment(t).toISOString() : undefined);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={Env.PUBLISH_DATE_TIME_INTERVAL}
              dateFormat="LLL"
              timeCaption="time"
            />
          </Col>
          <Col xs={6}>
            <ControlLabel>
              <FormattedMessage id="ADMIN.ANALYTICS.DATE_TO" />
            </ControlLabel>
            <DateTimePicker
              selected={to}
              onChange={(t) => {
                onSearch(null, 'to', moment(t).isValid() ? moment(t).toISOString() : undefined);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={Env.PUBLISH_DATE_TIME_INTERVAL}
              dateFormat="LLL"
              timeCaption="time"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FieldGroup onChange={onSearch} placeholderId="MAIN.SEARCH" value={search} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default FilterSection;
