export default {
  AUTH_TOKEN_ID: 'id',
  REFRESH_TOKEN_ID: 'refreshToken',
  LOCALE: 'en',
  ANALYST_BASE_PATH: 'analyst',
  EDGE_BASE_PATH: 'edge',
  EDGE_PLUS_BASE_PATH: 'edge-plus',
  ADMIN_BASE_PATH: 'admin',
  BREAKPOINTS: {
    MOBILE_SCREEN: 768,
    SMALL_SCREEN: 991,
    TABLET_SCREEN: 1160,
  },
  POST_GRADIENT_START_ANALYST: '#EC6059',
  POST_GRADIENT_END_ANALYST: '#232228',
  POST_GRADIENT_START_EDGE: '#2999e2',
  POST_GRADIENT_END_EDGE: '#278fd4',
  POST_GRADIENT_START_EDGE_PLUS: '#a1723e',
  POST_GRADIENT_END_EDGE_PLUS: '#e5b77b',
  MAX_FILE_SIZE: 20,
  MAX_NAME_LENGTH: 40,
  PASSWORD_PATTERN: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/,
  PHONE_PATTERN: /^(?:\+[1-9]\d{1,14})?$/,
  NUMBER_PATTERN: /^-?\d+\.?\d*$/,
  URL_PATTERN: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  HREF_PATTERN: /href="(.*?)"/g,
  BOOTSTRAP_MAX_WIDTH: 12,
  SCROLL_DURATION: 400,
  ANALYST_DEFAULT_IMAGE: require('assets/img/the-analyst-logo-light.svg'),
  EDGE_DEFAULT_IMAGE: require('assets/img/theanalystedge-logo-white.svg'),
  EDGE_DEFAULT_IMAGE_BLUE: require('assets/img/theanalystedge-logo-blue.svg'),
  EDGE_PLUS_DEFAULT_IMAGE: require('assets/img/theanalystedgeplus-logo-white.svg'),
  API_DATE_TIME_FORMAT: 'YYYY-MM-D HH:mm:ss',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  API_DATE_FORMAT: 'YYYY-MM-D',
  MODEL_PORTFOLIO_DATE_MONTH_YEAR_FORMAT: 'MMMM YYYY',
  FEEDBACK_DATE_MONTH_DAY_FORMAT: 'DD/MM',
  FEEDBACK_DATE_YEAR_FORMAT: 'YYYY',
  PUBLISH_DATE_TIME_INTERVAL: 15,
  EDGE_INSIGHTS_ITEM_PER_PAGE: 10,
  PRESS_ITEM_PER_PAGE: 3,
  MAX_TEXT_FIELD_LENGTH: 255,
  MAX_TEXT_EDITOR_LENGTH: 655365,
  COOKIEBOT_ID: 'd10aed14-f6cc-4901-aad9-11e8b440dac1',
  EDGE_FREE_TRIAL_UNDER_CONSTRUCTION: true,
  LIST_ITEMS_PER_PAGE_ADMIN: 100,
  SURVEY_LINK: '/app/survey',
  FEEDBACK_LINK: 'feedback-link',
  FEEDBACK_POPUP_DISPLAY_DURATION: 30000,
  SHOW_MORE_FEEDBACK_SURVEY_ID: 1,
  SHOW_FEEDBACK_CHARTS_ID: 2,
  NO_COMPANY_BOOKMARK: null,
  STOP_GLOWING: 'STOP_GLOWING',
  STOP_GLOWING_DURATION: 10000,
  SENTIMENT_TRACKER_MAX_VOTE_TO_SHOW_STAR: 9,
  POST_OLDER_FILTER_UNIT_TO_AREA_CHART: 1,
  CONTACT_MODAL_FILLED: 'CONTACT_MODAL_FILLED',
  QUERY_STRING_CONFIG: { arrayFormat: 'index' },
  LONG_TOAST_DURATION: 10000,
};
