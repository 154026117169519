import React from 'react';
import { Redirect } from 'react-router-dom';

import { LoginPermission, AdminPermission } from 'modules/shared';
import { NotFound } from 'modules/main';
import Admin from 'modules/admin';
import { CreatePassword, Login } from 'modules/analyst/modules/main';
import { Survey, CompanyFeedbackChart, AnalystApp } from 'modules/analyst/modules/app';
import Env from 'env';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/app`,
  //   component: LoginPermission(() => <Redirect to={`/${Env.ANALYST_BASE_PATH}`} />)(AnalystApp),
  //   name: 'Analyst',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/create-password/:token?`,
  //   component: CreatePassword,
  //   name: 'Create Password',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/profile`,
  //   component: LoginPermission()(Profile),
  //   name: 'Profile',
  //   strict: true,
  // },
  {
    path: `/app/login`,
    component: LoginPermission(() => <Login />)(() => <Redirect to={`/app`} />),
    name: 'Login',
    strict: true,
  },
  {
    path: `/app/create-password/:token?`,
    component: CreatePassword,
    name: 'Create Password',
    strict: true,
  },
  {
    path: '/app/survey',
    component: LoginPermission(() => <Redirect to={`/app`} />, true)(Survey),
    name: 'Survey',
    exact: true,
    strict: true,
  },
  {
    path: '/app/post/:postId/company/:companyId/feedback-chart',
    component: LoginPermission(() => <Redirect to={`/app`} />, true)(CompanyFeedbackChart),
    name: 'CompanyFeedbackChart',
    exact: true,
    strict: true,
  },
  {
    path: `/app/${Env.ADMIN_BASE_PATH}`,
    component: AdminPermission(() => <Redirect to={`/app`} />)(Admin),
    name: 'Admin',
    strict: true,
  },
  {
    path: `/app/404`,
    component: NotFound,
    name: 'Not Found',
    status: 404,
    exact: true,
  },
  {
    path: `/app`,
    component: LoginPermission(() => <Redirect to={`/app/login`} />)(AnalystApp),
    name: 'Analyst',
    strict: true,
  },
  {
    redirect: true,
    to: '/app/404',
    key: '404',
  },
];
