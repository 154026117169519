import React, { Component } from 'react';
import Env from 'env';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';

import { match, location } from 'common/propTypes';
import { withDimensions, AppSideMenu, SideMenuItem } from 'modules/shared';
import { SidebarTypes } from 'common';
import DrawerMenu from './DrawerMenu';
import DesktopDrawerMenu from './DesktopDrawerMenu';
import MobileRecommendationsDrawer from './MobileRecommendationsDrawer';

import styles from './AnalystSidebar.scss';

class AnalystSidebar extends Component {
  static propTypes = {
    open: PropTypes.bool,
    recommendations: PropTypes.object,
    toggleSidebar: PropTypes.func.isRequired,
    onSearchClick: PropTypes.func.isRequired,
    openSidebarDrawer: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    systemSettings: PropTypes.object.isRequired,
    editItem: PropTypes.object,
    windowWidth: PropTypes.number,
    hasAccess: PropTypes.bool,
    closeDrawers: PropTypes.bool,
    match,
    location,
  };

  static defaultProps = {
    recommendations: {},
  };

  state = {
    drawerState: this.closeDrawersState(),
    toggled: false,
  };

  /**
   * @description Gets the closed state object for all Drawers (Current Recommendation)
   *
   * @returns {Object}
   */
  closeDrawersState() {
    return Object.values(SidebarTypes).reduce(
      (prev, key) => ({ ...prev, [key]: { open: false, height: 0 } }),
      {}
    );
  }

  /**
   * @returns {boolean}
   */
  isAnyDrawersStateOpen() {
    return !!Object.values(this.state.drawerState).find(({ open }) => open);
  }

  /**
   * @description Gets the opened state object for all Drawers (Current Recommendation)
   *
   * @returns {Object}
   */
  openDrawersState() {
    return Object.values(SidebarTypes).reduce(
      (prev, key) => ({ ...prev, [key]: { open: true } }),
      {}
    );
  }

  /**
   * @description Sets the state to all drawers to be closed.
   */
  closeDrawers = () => {
    this.setState({ drawerState: this.closeDrawersState() });
  };

  /**
   * @description Close drawers on state change.
   * @param {Object} prevProps Props before update.
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location ||
      (this.props.closeDrawers &&
        this.isAnyDrawersStateOpen() &&
        this.props.windowWidth > Env.BREAKPOINTS.MOBILE_SCREEN)
    ) {
      this.closeDrawers();
    }

    if (!this.state.toggled) {
      this.setState({
        drawerState: this.closeDrawersState(),
        toggled: true,
      });
    }
  }

  /**
   * @param {string} type Buy Type
   *
   * @returns {number|null}
   */
  getDrawerHeight = (type) => {
    return this.props.windowWidth > Env.BREAKPOINTS.MOBILE_SCREEN &&
      this.state.drawerState[type].height
      ? null
      : this.state.drawerState[type].height;
  };

  /**
   * @param {string} type Type of the drawer to be toggled
   * @returns {Function}
   */
  toggleDrawer = (type) => () => {
    let drawerState;
    if (this.props.windowWidth > Env.BREAKPOINTS.MOBILE_SCREEN) {
      drawerState = {
        ...this.closeDrawersState(),
        [type]: {
          open: !this.state.drawerState[type].open,
        },
      };
      if (drawerState[type].open) {
        this.props.openSidebarDrawer();
      }
    } else {
      drawerState = {
        ...this.state.drawerState,
        [type]: {
          open: !this.state.drawerState[type].open,
        },
      };
    }

    this.setState({ drawerState }, () => {
      const openDrawer = document.querySelector('.recommendation-drawer.open .companies');
      this.setState({
        drawerState: {
          ...this.state.drawerState,
          [type]: {
            ...this.state.drawerState[type],
            height: this.state.drawerState[type].open
              ? openDrawer && openDrawer.offsetHeight
                ? openDrawer.offsetHeight
                : 0
              : 0,
          },
        },
      });
    });
  };

  getOpenDrawerHeight = () => {
    const openDrawer = Object.values(this.state.drawerState).find(({ open }) => open);
    return openDrawer && openDrawer.height ? openDrawer.height : 0;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { open, basePath, hasAccess } = this.props;
    const drawerOpen = !!Object.values(this.state.drawerState).filter((drawer) => !!drawer.open)
      .length;

    const isDesktopScreen = this.props.windowWidth > Env.BREAKPOINTS.MOBILE_SCREEN;

    return (
      <AppSideMenu
        open={open}
        drawerOpen={drawerOpen}
        className="analyst-sidebar dark"
        hasAccess={hasAccess}
        toggleSidebar={this.props.toggleSidebar}
        getExtraHeight={this.getOpenDrawerHeight}
        onSearchClick={this.props.onSearchClick}
        editItem={this.props.editItem}
        showBackdrop={isDesktopScreen && Object.values(this.state.drawerState).some((i) => i.open)}
        onBackdropClick={this.closeDrawers}>
        <SideMenuItem
          to={`${this.props.match.path}/all-research`}
          iconClass="icon-book-outline"
          title={<FormattedMessage id="MAIN.RESEARCH" />}
        />
        <MobileRecommendationsDrawer
          title="Recommendations"
          icon={<i className="module-icon icon-check-outline" />}>
          <DrawerMenu
            basePath={basePath}
            className="with-flyout-menu"
            title={<FormattedMessage id="MAIN.BUY_TYPES.LONG" />}
            open={this.state.drawerState[SidebarTypes.LONG].open}
            icon={<i className="module-icon icon-buy-outline" />}
            companies={this.props.recommendations[SidebarTypes.LONG]}
            toggleDrawer={this.toggleDrawer(SidebarTypes.LONG)}
            drawerHeight={this.getDrawerHeight(SidebarTypes.LONG)}
          />
          <DrawerMenu
            basePath={basePath}
            className="with-flyout-menu"
            title={<FormattedMessage id="MAIN.BUY_TYPES.SHORT" />}
            open={this.state.drawerState[SidebarTypes.SHORT].open}
            icon={<i className="module-icon icon-short-outline" />}
            companies={this.props.recommendations[SidebarTypes.SHORT]}
            toggleDrawer={this.toggleDrawer(SidebarTypes.SHORT)}
            drawerHeight={this.getDrawerHeight(SidebarTypes.SHORT)}
          />
        </MobileRecommendationsDrawer>
        <div className="hide-on-mobile">
          <DesktopDrawerMenu
            basePath={basePath}
            className="with-flyout-menu"
            title="Recommendations"
            open={this.state.drawerState[SidebarTypes.RECOMMENDATIONS].open}
            icon={<i className="module-icon icon-check-outline" />}
            companies={this.props.recommendations}
            toggleDrawer={this.toggleDrawer(SidebarTypes.RECOMMENDATIONS)}
          />
        </div>
        <SideMenuItem
          onClick={this.stopGlowing}
          to={`${this.props.match.path}/sentiment-tracker`}
          svgIconClass="sentiment-tracker-icon"
          svgIconClassHover="sentiment-tracker-icon-active"
          title={<FormattedMessage id="ADMIN.SENTIMENT_TRACKER.TITLE" />}
          feature="sentiment_tracker"
          isNewFeature={this.props.systemSettings.sentiment_tracker_new_feature === 'true'}
          snowNewFeatureIcon={open}></SideMenuItem>
        <SideMenuItem
          to={`${this.props.match.path}/model-portfolio`}
          iconClass="icon-squares-outline"
          title={<FormattedMessage id="ANALYST.MODEL_PORTFOLIO.TITLE" />}
        />
        <SideMenuItem
          to={`${this.props.match.path}/short-screen`}
          iconClass="icon-short-screen"
          title={<FormattedMessage id="ADMIN.SHORT_SCREEN.TITLE" />}
        />
        <SideMenuItem
          to={`${this.props.match.path}/factor-panel`}
          svgIconClass="long-screen"
          svgIconClassHover="long-screen-active"
          title={<FormattedMessage id="ADMIN.LONG_SCREEN.TITLE" />}
          feature="long_screen"
          isNewFeature={this.props.systemSettings.long_screen_new_feature === 'true'}
          snowNewFeatureIcon={open}
        />
        <SideMenuItem
          to={`${this.props.match.path}/companies`}
          iconClass="icon-a-z"
          title={<FormattedMessage id="MAIN.A_Z_COMPANIES" />}
        />
        <SideMenuItem
          to={`${this.props.match.path}/bookmarks`}
          iconClass="icon-bookmark-outline"
          title={<FormattedMessage id="MAIN.BOOKMARKS" />}
        />
      </AppSideMenu>
    );
  }
}

export default withDimensions(withRouter(withStyles(styles)(AnalystSidebar)));
